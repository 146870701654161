define('point-and-click/js/PCGame',['./Interactable', './Cursor', './Collectable', 'dialog', 'underscore'], function(Interactable, Cursor, Collectable, Dialog, _){

    var PCGame = function PCGame (settings, stage, assetsPath, loadedCallback, loadingProgressCallback, winCallback) {

        this.stageContainer = null;
        this.collectables = {};
        this.icons = {};
        this.interactables = {};
        this.scrollLeftButton = null;
        this.scrollRightButton = null;
        this.inventoryContainer = null;
        this.activeCursor = ''; // string
        this.disablePickups = false;
        this.startMessage = 'start';

        var customLogic; // object defined in customJS file
        var exportRoot;
        var messageContainer;
        var cursors = {}; // dict <name, cursor>
        var scrollDirection; //the current direction of scrolling
        var scrollCenter = {x:0}; // the current center (in stageContainer coordinates) we've scrolled to
        var zoom = {zoom:1}; // the current magnification factor, this is an object for tweening purposes
        var scale = settings.scale || 2;
        var lastMessage;
        var dragPosition;
        var scrollDirty;
        //region cursor click and move

        function AddCursor (cursor) {
            cursors[cursor.name] = cursor;
        }

        this.CursorClick = function(event) {
            if(cursors[this.activeCursor]!=null)
                cursors[this.activeCursor].CursorClick(event);
        }.bind(this);


        this.CursorMove = function(event) {
            if(cursors[this.activeCursor]!=null)
                cursors[this.activeCursor].CursorMove(event);
        }.bind(this);

        this.TouchEnd = function(event) {
            if(event.isTouch)
                cursors[this.activeCursor].Stop(event);
        }.bind(this);

        this.SetCursor = function(newCursor) {
            var prevCursor = this.activeCursor;
            if(this.collectables[prevCursor]!=null) {
                if ( !this.collectables[prevCursor].consumed && !this.collectables[prevCursor].respawns )
                    return;
            }
            this.activeCursor = newCursor;
            if(cursors[prevCursor]!=null)
                cursors[prevCursor].Stop();
            //        delete cursors[prevCursor];
        }

        this.ClearCursor = function() {
            var prevCursor = this.activeCursor;
            this.activeCursor = null;
//            if(cursors[prevCursor]!=null)
//                cursors[prevCursor].Stop();
        }
        //endregion


        //region Setup and initialization

        this.init = function()
        {
            window.playSound = function(id, _loop) {
                var sound = createjs.Sound.play(id, {interrupt: createjs.Sound.INTERRUPT_ANY, loop: (_loop?-1:0), volume: 1});
                if(sound.playState!="playSucceeded")
                    console.log(sound);
                if(!_loop) {
                    sound.on("complete", function(e){e.target.destroy();});
                }
                return sound;
            };

            var queue = new createjs.LoadQueue(true);
            queue.installPlugin(createjs.Sound);
            if ( loadingProgressCallback ) {
                queue.on("progress", function(e){ loadingProgressCallback.call(null, e.progress)});
            }

            // load exported javascript file
            var deps = [assetsPath+'/'+settings.exportedJS];
            if ( settings.customJS ) {
                deps.push(assetsPath+'/'+settings.customJS);
            }

            this.assetsPath = assetsPath; // for anything else that needs to know

            // Preload sounds
            for(var i in settings.sounds) {
                var src = assetsPath + '/' + settings.sounds[i];
                queue.loadFile({src:src, id:i}, false);
//                createjs.Sound.registerSound(src, settings.sounds[i]);
            }

            for(var i in settings.messages) {
                var message = [].concat(settings.messages[i]); //make it an array even if it isn't
                for (var j in message) {
                    if(message[j].sound!=null) {
                        var src = assetsPath + '/' + message[j].sound;
                        if(message[j].preload === true)
                            queue.loadFile({src:src, id:message[j].sound}, false);
//                        createjs.Sound.registerSound(src, message[j].sound);
                    }
                }
            }

            var things = _.extend(settings.interactables.concat, settings.items);
            for(var i in things) {
                if (things[i].messages!=null) {
                    for (var j in things[i].messages) {
                        if ( things[i].messages[j].sound!=null) {
                            var src = assetsPath + '/' + things[i].messages[j].sound;
                            queue.loadFile({src:src, id:things[i].messages[j].sound}, false);
//                            createjs.Sound.registerSound(src, things[i].messages[j].sound);
                        }
                    }
                }
            }

            require(deps, (function(exported, custom){
                var replacement = '/2x/';
                // update paths in that file
                lib.properties.manifest.forEach(function(item){
                    item.src =  assetsPath + '/' + item.src;
                    //if(scale == 1)
                    //{
                    //    item.src = item.src.replace(/\/([^\/]*)$/,replacement+'$1');
                    //}
                });

                // preload all images and sounds from that file
                queue.loadManifest(lib.properties.manifest, false);

                queue.addEventListener("fileload", handleFileLoad);

                images = images || {};

                function handleFileLoad(evt) {
                    if (evt.item.type == "image") {
                        images[evt.item.id] = evt.result;
                    }
                }

                customLogic = custom;
                
                queue.on("complete", this.loadingComplete, this);
                queue.load();
                
            }).bind(this));

        }

        this.loadingComplete = function(){
            // exported js from flash
            exportRoot = new lib.unimind();
            recur(exportRoot);

            function recur(thisContainer)
            {

                for(var i in thisContainer)
                {
                    if(i=="parent") continue;

                    var thisChild = thisContainer[i];

                    if(thisChild instanceof createjs.Container)
                    {
                        recur(thisChild);
                    }
                    else if (thisChild instanceof createjs.Bitmap)
                    {
                        thisChild.scaleX = scale;
                        thisChild.scaleY = scale;
                    }
                }
            }

            this.stageContainer = exportRoot.stageContainer;
            this.stageContainer.defaultScale = this.stageContainer.scaleX;

            stage.addChild(exportRoot);

            scrollCenter.x = settings.worldWidth/2;
            scrollDirty = true;

            stage.addEventListener("mousedown", this.CursorClick);
            stage.addEventListener("stagemousemove", this.CursorMove);
            stage.addEventListener("touchend", this.CursorMove);

            // navigation buttons
            this.scrollLeftButton = exportRoot[settings.scrollButtons.left];
            this.scrollRightButton = exportRoot[settings.scrollButtons.right];

            this.scrollRightButton.addEventListener("mousedown", this.ScrollRight);
            this.scrollRightButton.addEventListener("pressup", this.ScrollStop);
            this.scrollLeftButton.addEventListener("mousedown", this.ScrollLeft);
            this.scrollLeftButton.addEventListener("pressup", this.ScrollStop);

            this.enableScrolling();

            createjs.Ticker.setFPS(lib.properties.fps);
            createjs.Ticker.addEventListener("tick", this.tick);
            createjs.Ticker.addEventListener("tick", stage);

            createjs.Touch.enable(stage);

            //
            //                    for(i in this.stageContainer.children)
            //                    {
            //                        this.stageContainer.children[i].visible = false;
            //                    }
            //
            //                    this.stageContainer.backgroundSliced.visible = false;
            this.stageContainer.backgroundSliced.cache(this.stageContainer.backgroundSliced.nominalBounds.x, this.stageContainer.backgroundSliced.nominalBounds.y, this.stageContainer.backgroundSliced.nominalBounds.width, this.stageContainer.backgroundSliced.nominalBounds.height, 0.25)

            // set up stuff from json
            this.setupInteractableObjects();
            this.SetCursor(settings.defaultcursor);
            this.setupMessageBox();

            // all done
            stage.update();
            loadedCallback.call();

            window.addEventListener('resize', this.handleResize.bind(this));
            this.handleResize();

            // initialize custom logic. do this last so any errors don't break the standard module code.
            if(customLogic!=null)
                customLogic.init(this);

        }.bind(this);

        this.setupInteractableObjects = function() {

            // Cursors
            for(i in settings.cursors)
            {
                var params = settings.cursors[i];
                params.target = this.stageContainer[params.name];
                var cursor = new Cursor (params, this);
                AddCursor(cursor);
            }

            this.inventoryContainer = exportRoot["inventoryContainer"];

            // Collectables (and any implied cursors)
            for(i in settings.items) {
                var params = settings.items[i];
                var collectable = new Collectable(params, this);
                this.collectables[collectable.name] = collectable;
                var inventoryIcon = this.inventoryContainer[collectable.icon];
                inventoryIcon.visible = false;
                this.icons[collectable.name] = inventoryIcon;

                if(cursors[params.name]==null)
                {
                    params.target = this.stageContainer[params.cursor];
                    var cursor = new Cursor (params, this);
                    AddCursor(cursor);
                }
            }

            // Interactables
            for(i in settings.interactables) {
                var params = settings.interactables[i];

                if( this.stageContainer[params.name]!=null) {
                    params.target = this.stageContainer[params.name];
                }
                else {
                    console.log("can't find object named " + params.name);
                    break;
                }
                if(params.buttontarget!=null) {
                    var names = params.buttontarget instanceof Array ? params.buttontarget : [params.buttontarget];
                    params.buttontarget = [];
                    for(i in names) {
                        if( this.stageContainer[names[i]]!=null) {
                            params.buttontarget[i] = this.stageContainer[names[i]];
                        }
                        else {
                            console.log("can't find object named " + names[i]);
                        }
                    }
                }
                else if(params.toggletarget!=null) {
                    var names = params.toggletarget instanceof Array ? params.toggletarget : [params.toggletarget];
                    params.toggletarget = [];
                    for(i in names) {
                        if( this.stageContainer[names[i]]!=null) {
                            params.toggletarget[i] = this.stageContainer[names[i]];
                        }
                        else {
                            console.log("can't find object named " + names[i]);
                        }
                    }
                }

                var interactable = new Interactable(params, this);
                this.interactables[params.name] = interactable;
            }

        }.bind(this);

        this.setupMessageBox = function setupMessageBox() {
            messageContainer = exportRoot["messageContainer"];
            if(messageContainer == null) {
                messageContainer = this.stageContainer["messageContainer"];
            }
            if(messageContainer == null) {
                console.log("Can't find messageContainer");
            }
            else {
                //messageContainer.addEventListener("mousedown", this.advanceMessageSequence.bind(this));
                messageContainer['messageText'].text = '';
            }
        }.bind(this);

        this.start = function() {
            if (this.startMessage) {
                this.showLocalizedMessage(this.startMessage);
            }
            if(settings.sounds["background_music"]) {
                playSound("background_music");
            }
        }.bind(this);

        this.loadAllMessageSounds = function() {
            for(var i in settings.messages) {
                var message = [].concat(settings.messages[i]); //make it an array even if it isn't
                for (var j in message) {
                    if(message[j].sound!=null) {
                        var src = assetsPath + '/' + message[j].sound;
                        createjs.Sound.registerSound(src, message[j].sound);
                    }
                }
            }
        }
        
        this.pause = function() {
            //console.log("point and click paused");
            if(this.messageSound) {
                //                TweenMax.to(this.messageSound, 1, {volume:0});
                this.messageSound.paused=true;
                this.messageSound.stop();
                //TODO: kill any ambient sounds that may be playing
            }
            stage.tickEnabled = false;
            clearInterval(this.messageUpdateInterval);

            //            stage.removeEventListener("tick", this.tick);
            //            createjs.Ticker.removeEventListener("tick", stage);
        }
        this.resume = function() {
            //console.log("point and click resumed");
            stage.tickEnabled = true;
            //            stage.addEventListener("tick", this.tick);
            //            createjs.Ticker.addEventListener("tick", stage);
        }

        this.disableScrolling = function() {
            this.scrollLeftButton.visible = this.scrollRightButton.visible = false;
            stage.removeEventListener("mousedown", this.StartDrag);
            stage.removeEventListener("pressmove", this.Drag);
        }

        this.enableScrolling = function() {
            this.scrollLeftButton.visible = this.scrollRightButton.visible = true;
            stage.addEventListener("mousedown", this.StartDrag);
            stage.addEventListener("pressmove", this.Drag);
        }

        //endregion

        //region scrolling
        this.tick = function(e){
            if ( scrollDirection ) {
                var scrollAmount = e.delta * 1;
                scrollCenter.x += scrollAmount * scrollDirection;
                scrollDirty = true;
            }
            if(scrollDirty) { // so that updateScrollAndZoom only gets called once per tick
                this.updateScrollAndZoom();
                scrollDirty = false;
            }
        }.bind(this);

        this.updateScrollAndZoom = function() {

            this.stageContainer.scaleX = this.stageContainer.scaleY = this.stageContainer.defaultScale * zoom.zoom;

            var maxX = settings.worldWidth - stage.canvas.width / stage.scaleX / this.stageContainer.scaleX / 2;
            var minX = stage.canvas.width / stage.scaleX / this.stageContainer.scaleX / 2;
            scrollCenter.x = Math.max(minX, Math.min(maxX, scrollCenter.x));

            this.stageContainer.regX = scrollCenter.x - stage.canvas.width / stage.scaleX / this.stageContainer.scaleX / 2;
            this.stageContainer.regY = settings.worldHeight/2 - stage.canvas.height / stage.scaleY / this.stageContainer.scaleY / 2;
        }

        this.handleResize = function() {
            // reposition right scroll button within fluid canvas to match left scroll button
            exportRoot[settings.scrollButtons.right].x = stage.canvas.width / stage.scaleX - exportRoot[settings.scrollButtons.left].x;
            this.inventoryContainer.x = stage.canvas.width / stage.scaleX - exportRoot[settings.scrollButtons.left].x;

            // re-center
            scrollDirty = true;
        }

        this.ScrollLeft = function() {
            scrollDirection = -1;
        }

        this.ScrollRight = function() {
            scrollDirection = 1;
        }

        this.ScrollStop = function() {
            scrollDirection = 0;
        }

        this.Pan = function(localCoords, duration) {
            duration = duration || 500;
            var onChange = function(){
                this.setScrollCenter(scrollCenter);
            }.bind(this);
            createjs.Tween.get(scrollCenter, {onChange: (function(){scrollDirty=true;}).bind(this)}).to({x: localCoords.x}, duration);
        }

        this.PanToCenter = function() {
            this.Pan({x:settings.worldWidth/2});
        }

        this.Zoom = function(magnification, duration) {
            duration = duration || 500;
            createjs.Tween.get(zoom, {onChange: (function(){scrollDirty=true;}).bind(this)}).to({zoom: magnification}, duration);
        }

        this.StartDrag = function(evt) {
            dragPosition = {x:evt.stageX, y:evt.stageY};
        }.bind(this);

        this.Drag = function(evt) {
            var newDragPosition = {x:evt.stageX, y:evt.stageY};
            scrollCenter.x += ( dragPosition.x - newDragPosition.x ) / stage.scaleX / this.stageContainer.scaleX;
            scrollDirty = true;
            dragPosition = newDragPosition;
        }.bind(this);
        //endregion

        //region object pickups

        this.Collect = function (collectable) {
            if(collectable.collected)
                return false;
            if(this.disablePickups)
            {
                this.showLocalizedMessage("cant_pickup");
                this.dispatchEvent("failed_pickup");
                return false;
            }
            if(this.collectables[this.activeCursor]!=null) {
                if ( !this.collectables[this.activeCursor].consumed && !this.collectables[this.activeCursor].respawns )
                {
                    this.showLocalizedMessage("hands_full");
                    return false;
                }
            }

            this.SetCursor(collectable.name);
            collectable.Collect();
            return true;
        }

        this.Consume = function (collectable) {
            this.icons[collectable.name].visible = false;
            collectable.Consume();
        }
        //endregion
        //region dialogs and sounds

        // High level call for displaying message sequences & voiceover.
        this.showLocalizedMessage = function (messageTag) {
            if(messageTag == "lastMessage") {
                this.showMessage(lastMessage);
                return;
            }

            var message = typeof messageTag == 'string' ? settings.messages[messageTag] : messageTag;

            if ( message ) {
                this.currentMessageSequence = message instanceof Array ? message : [message];
                this.currentMessageSequenceIndex = -1;
                this.advanceMessageSequence();

                this.Pan({x:settings.worldWidth/2}, 500);
            }
            if (settings.sounds[messageTag]) {
                playSound(messageTag);
            }
        }

        this.advanceMessageSequence = function() {

            this.currentMessageSequenceIndex++;
            if ( this.currentMessageSequenceIndex < this.currentMessageSequence.length ) {
                if (this.messageSoundPlaying) {
                    this.messageSound.paused=true;
                    this.messageSound.stop();
                }
                var message = this.currentMessageSequence[this.currentMessageSequenceIndex];
                this.messageSoundPlaying = false;
                if ( message.sound ) {
                    this.messageSound = playSound(message.sound);
                    //                    console.log(this.messageSound);
                    if(this.messageSound.playState=="playSucceeded") {
                        this.messageSoundPlaying = true;
                        this.messageSound.on("complete", function(){this.messageSoundPlaying = false;}.bind(this));
                    }
                }
                this.showMessage(message.text || "");
            }
        }

        // Low level call for displaying actual text.
        this.showMessage = function (messageString) {
            if(messageContainer == null)
            {
                console.log("Can't find messageContainer");
                return;
            }
            messageContainer.gotoAndPlay("On");

            var textInstance = messageContainer["messageText"];
            var char = 0;
            var delay = 50;
            var endingDelay = 2000;

            if ( this.messageUpdateInterval ) {
                clearInterval(this.messageUpdateInterval);
            }

            this.messageUpdateInterval = setInterval(update.bind(this), delay);
            update();

            function update() {
                textInstance.text = messageString.substr(0, char++);
                if ( char >= messageString.length + endingDelay/delay ) { // adding to the character count is a hacky but effective way to add the ending delay
                    //console.log("text done, waiting for sound to finish playing");
                    if (!this.messageSoundPlaying) {
                        //console.log("message display complete");
                        clearInterval(this.messageUpdateInterval);
                        this.advanceMessageSequence();
                    }
                }
                else if (!this.messageSoundPlaying && char > 1) {
                    //console.log( "sound done, waiting for text+delay to complete" );
                }
            }

            lastMessage = messageString;
        }

        this.hideMessage = function () {
            if(messageContainer == null)
            {
                console.log("Can't find messageContainer");
                return;
            }
            messageContainer.gotoAndPlay("Off");
        }
        //endregion

        this.showEnding = function() {
            this.dispatchEvent('completed');
            window.setTimeout(winCallback, 2000);

            if (this.messageSoundPlaying) {
                this.messageSound.paused=true;
                this.messageSound.stop();
            }
            if(settings.sounds["win"]) {
                playSound("win");
            }

        }

        this.init();

        window.debugPCGame = this;
    }

    createjs.EventDispatcher.initialize(PCGame.prototype);

    return PCGame;

});

