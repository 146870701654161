define('flambe/module',[],function(){

    var Module = function Module(element, config, assetsPath, loadedCallback, loadingProgressCallback, instructionsCallback, winCallback) {

        var iframe = document.createElement('iframe');
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.src = assetsPath + '/' + config.index;
        iframe.style.display = 'block';
        element.appendChild(iframe);

        attachCallbacks();

        this.start = function() {
            tryToStartGame();
        };

        function attachCallbacks() {
            iframe.contentWindow.flambeLoadedCallback = loadedCallback;
            iframe.contentWindow.flambeLoadingProgressCallback = loadingProgressCallback;
            iframe.contentWindow.flambeWinCallback = winCallback;
        }

        function tryToStartGame() {
            // the flambe game will take a while to initialize it's start function, so keep trying till it exists
            if ( iframe.contentWindow.flambeStart != null ) {
                iframe.contentWindow.flambeStart();
            } else {
                setTimeout(tryToStartGame, 100);
            }
        }

    };

    return Module;

});
define('flambe', ['flambe/module'], function (main) { return main; });

