define('point-and-click/js/Collectable',[], function() {

    function Collectable(params, PCGame) {

        this.name = params.name;
        this.cursor = params.cursor;
        this.icon = params.icon
        this.respawns = params.respawns || false;
        var messages = params.messages || {};

        this.collected = false;
        this.consumed = false;

        this.Collect = function () {
            this.collected = true;
            this.consumed = false;
            //console.log("got " + this.name);
            PCGame.icons[this.name].visible = true;
            PCGame.showLocalizedMessage(messages["collect"] || "collect");
        }

        this.Consume = function () {
            this.consumed = true;
            if ( this.respawns )
                this.Respawn();
            PCGame.showLocalizedMessage(messages["consume"] || "collect");
        }

        this.Respawn = function () {
            this.collected = false;
            this.consumed = false;
        }

        this.init = function init(params) {
        }

        this.init(params);
    }

    return Collectable;
});

