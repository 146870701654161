define('framework/Debug',['require','jquery','json!modules/config.json'],function(require){
    var $ = require('jquery');
    var ModulesConfig = require('json!modules/config.json');

    var Debug = function(){

        var menu = $('<div>');
        menu.css({
            'box-sizing': 'border-box',
            'padding-top': 50,
            'background': 'white',
            'position': 'absolute',
            'top': 0,
            'left': 0,
            'overflow': 'hidden'
        });
        menu.on('click', function(){ menu.toggleClass('open'); updateMenu(); });
        updateMenu();

        function updateMenu() {
            if ( menu.hasClass('open') ) {
                menu.css({
                    'width': 'auto',
                    'height': 'auto',
                    'opacity': 1
                });
            } else {
                menu.css({
                    'width': 50,
                    'height': 50,
                    'opacity': 0.3
                });
            }
        }

        for ( var name in ModulesConfig ) {
            var link = $('<a>').attr('href', '?module='+name).text(name).wrap('<p></p>').parent();
            menu.append(link);
        }



        this.attach = function() {
            $('body').append(menu);
        }

    };

    return Debug;
});
