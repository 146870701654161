define('scavenger/module',['require','jquery','underscore','text!./template.tpl','tweenmax','sound','preload','typewriter'],function(require){
    var $ = require('jquery');
    var _ = require('underscore');
    var template = require('text!./template.tpl');
    require('tweenmax');
    require('sound');
    require('preload');
    require('typewriter');

    var Module = function Module(element, config, assetsPath, loadedCallback, loadingProgressCallback, instructionsCallback, winCallback ) {
        assetsPath += assetsPath.length ? '/' : '';

        var settings = {};
        var activeClue = 0;
        var totalClues = config.questions.filter(function(o){return o.hasOwnProperty('clue')}).length;
        var container;

        this.init = function() {

            settings = config;

            // preloading
            var queue = new createjs.LoadQueue(true);
            queue.installPlugin(createjs.Sound);

            for(var i=0; i<settings.questions.length; i++){
                if ( settings.questions[i].hasOwnProperty('hintImage') ) {
                    queue.loadFile(assetsPath + settings.questions[i].hintImage);
                }
            }

            if ( settings.start.introVO )
                queue.loadFile({id:"scavenger-intro-vo", src: assetsPath + settings.start.introVO});
            if ( settings.end.endingVO )
                queue.loadFile({id:"scavenger-ending-vo", src: assetsPath + settings.end.endingVO});
            if( settings.clueVO )
                queue.loadFile({id:"scavenger-clue-vo", src: assetsPath + settings.clueVO});
            if ( settings.questionVO )
                queue.loadFile({id:"scavenger-question-vo", src: assetsPath + settings.questionVO});
            queue.loadFile({id:"scavenger-music", src: assetsPath + settings.music});
            queue.loadFile({id:"scavenger-click", src:assetsPath + settings.buttonSound});
            queue.loadFile({id:"scavenger-error", src:assetsPath + settings.errorSound});
            queue.loadFile({id:"scavenger-reward", src:assetsPath + settings.rewardSound});
            queue.loadFile({id:"scavenger-win", src:assetsPath + settings.winSound});
            queue.loadFile({id:"scavenger-screen-appear", src:assetsPath + settings.screenAppearSound});
            queue.loadFile({id:"scavenger-button-appear", src:assetsPath + settings.buttonAppearSound});

            // insert css and preload images in it
            $('head').append($('<link>').attr({rel: 'stylesheet', href: assetsPath + 'module.css'}));
            $.get(assetsPath+'module.css', function(response){
                var matches = [];
                response.match(/url\([^)]+\)/g).forEach(function(match){
                    match = match.match(/url\(['"]?(.*?)['"]?\)/)[1];
                    if ( !_(matches).contains(match) ) {
                        queue.loadFile(assetsPath + match);
                        matches.push(match);
                    }
                });
            });

            queue.on("complete", loadedCallback);
            if ( loadingProgressCallback ) {
                queue.on("progress", function (e) {
                    loadingProgressCallback.call(null, e.progress)
                });
            }

            // initialize content
            $(element).addClass('scavenger-screen');

            container = $('<div>').appendTo(element);
            container.addClass("scavenger-container");
            container.html(template);
            container.find('.scavenger-startPage-header').text(settings.start.headerText);
            container.find('.scavenger-startPage-intro').html(settings.start.introText);
            container.find('.scavenger-clue-noun-singular').text(settings.noun.singular);
            container.find('.scavenger-clue-noun-plural').text(settings.noun.plural);
            container.find('.scavenger-clue-total').text(totalClues);
            for ( var i=0; i<totalClues; i++ ) container.find('.scavenger-success-checklist').append('<li>'+(i+1)+'</li>');


            // events

            container.find('.scavenger-startButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.showMain();
            }.bind(this));

            container.find('.scavenger-foundItButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.showMainQuestion();
            }.bind(this));

            container.find('.scavenger-submitButton').on("click",(function(){
                container.find('form.scavenger-answer').submit();
            }).bind(this));

            container.find('form.scavenger-answer').on('submit', function(){
                createjs.Sound.play("scavenger-click");
                container.find('.scavenger-input').blur();
                this.doSubmit();
                return false;
            }.bind(this));

            container.find('.scavenger-nextButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                activeClue++;

                if ( activeClue == settings.questions.length ) {
                    this.showEnd();
                    return;
                }

                this.showMain();
            }.bind(this));

            container.find('.scavenger-endButton').on("click", _.once(function(){
                createjs.Sound.play("scavenger-click");
                winCallback.call();
            }.bind(this)));

            container.find('.scavenger-hintButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.showHint();
            }.bind(this));

            container.find('.scavenger-hintContinueButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.showMain();
            }.bind(this));

            container.find('.scavenger-hintHelpButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.showParentCheck();
            }.bind(this));

            container.find('.scavenger-parentCancelButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.showMain();
            }.bind(this));

            container.find('.scavenger-parentLoginButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.doParentCheck();
            }.bind(this));

            container.find('.scavenger-parentSkipButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.showSuccess();
            }.bind(this));

            container.find('.scavenger-parentContinueButton').on("click",function(){
                createjs.Sound.play("scavenger-click");
                this.showMain();
            }.bind(this));

            container.find('.scavenger-input').on("focus", function(){
                $(this).attr('placeholder', '');
                container.find('.scavenger-incorrect').hide();
            });

            // sizing/scaling behaviour
            function resize(){
                var winWidth = $(element).innerWidth();
                var winHeight = $(element).innerHeight();
                var elemWidth = 576; //lock dimensions at 16:9 portrait at iPad height
                var elemHeight = 1024;
                var scaleX = winWidth / elemWidth;
                var scaleY = winHeight / elemHeight;
                var scale = Math.min(scaleX, scaleY);
                container.css({
                    position: 'absolute',
                    transform: 'scale('+scale+')',
                    transformOrigin: '0 0',
                    left: (winWidth-scale*elemWidth)/2,
                    top: (winHeight-scale*elemHeight)/2,
                    width: elemWidth,
                    height: elemHeight
                });
            }
            resize();
            $(window).resize(resize);
        };

        this.showPage = function(selector){
            var page = container.find(selector);

            if ( this.currentPopover ) {
                this.currentPopover.hide();
                this.currentPopover = null;
            }

            if ( page.hasClass('scavenger-popover') ) {
                page.fadeIn();
                this.currentPopover = page;
            }
            else {
                if ( this.currentPage ) {
                    this.currentPage.hide();
                }
                page.fadeIn();
                this.currentPage = page;
            }

        }.bind(this);

        this.doSubmit = function(){
            if( settings.questions[activeClue].answers.indexOf( container.find('.scavenger-input').val().toLowerCase().trim() ) > -1) {
                this.showSuccess();
            }
            else if(container.find('.scavenger-input').val()!=""){
                createjs.Sound.play('scavenger-error');
                container.find('.scavenger-input').val('');
                container.find('.scavenger-incorrect').show();
            }
        }.bind(this);

        this.doParentCheck = function(){
            // Send the data using post
            if ( settings.parentEmail ) {
                $.post( settings.parentAuthUrl, { "email": settings.parentEmail, "password": container.find('.scavenger-parentPassword').val()} )
                    .done(function() {
                        this.showParentPage();
                    }.bind(this))
                    .fail(function() {
                        alert("Incorrect password.");
                    });
            }
            else {
                this.showParentPage();
            }
        }.bind(this);

        this.showMain = function(){
            if ( settings.questions[activeClue].hasOwnProperty('clue') ) {
                container.find('.scavenger-mainPage-question-wrapper').hide();
                container.find('.scavenger-mainPage-question-buttons').hide();
                container.find('.scavenger-mainPage-object-wrapper').show();
                container.find('.scavenger-mainPage-object-buttons').show();

                container.find('.scavenger-mainPage').removeClass('clueless');
                container.find('.scavenger-clue').html(settings.questions[activeClue].clue);
                container.find('.scavenger-mainPage').find('.scavenger-clue-number').text(activeClue + 1);
                container.find('.scavenger-mainPage-object-image').attr('src', assetsPath + settings.questions[activeClue].hintImage);
            }
            else {
                container.find('.scavenger-mainPage-question-wrapper').show();
                container.find('.scavenger-mainPage-question-buttons').show();
                container.find('.scavenger-mainPage-object-wrapper').hide();
                container.find('.scavenger-mainPage-object-buttons').hide();

                container.find('.scavenger-mainPage').addClass('clueless');
                container.find('.scavenger-clueless-heading').html(settings.questions[activeClue].heading);
            }

            container.find('.scavenger-question').html(settings.questions[activeClue].question);
            container.find('.scavenger-input').val('');
            container.find('.scavenger-incorrect').hide();

            // animations
            container.find('.scavenger-mainPage-wrapper').hide().delay(500).slideDown(1000);
            TweenMax.fromTo(container.find('.scavenger-mainPage-clue-wrapper'), 0.5, {scale:0}, {scale:1, delay:1.3});
            TweenMax.fromTo(container.find('.scavenger-mainPage-question-wrapper, .scavenger-mainPage-object-wrapper'), 0.5, {alpha:0}, {alpha:1, delay:2});
            TweenMax.fromTo(container.find('.scavenger-mainPage .scavenger-buttons button:nth-of-type(1)'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut, delay:2.5});
            TweenMax.fromTo(container.find('.scavenger-mainPage .scavenger-buttons button:nth-of-type(2)'), 0.5, {alpha:0, left:+400}, {alpha:1, left:0, ease:Back.easeOut, delay:2.8});
            createjs.Sound.play('scavenger-screen-appear', {delay:500});
            createjs.Sound.play('scavenger-button-appear', {delay:2500});
            createjs.Sound.play('scavenger-button-appear', {delay:2800});

            if ( activeClue == 0 ) {
                createjs.Sound.play('scavenger-clue-vo');
            }

            // Dynamically resize the clue text to fit inside the box. This needs to happen after the box and its parents are no longer hidden
            setTimeout(function() {
                var clueContainer = container.find('.scavenger-mainPage-wrapper .scavenger-clue');
                this.defaultClueFontSize = this.defaultClueFontSize || parseInt(clueContainer.css('font-size'));
                var fontSize = this.defaultClueFontSize;
                var sizerDiv = $('<div>').html(clueContainer.html()).css({
                    visibility: 'hidden',
                    fontSize: fontSize + 'px'
                });
                clueContainer.append(sizerDiv);
                while ( sizerDiv.height() > clueContainer.height() ) {
                    sizerDiv.css('font-size', fontSize-- + 'px');
                }
                clueContainer.css('font-size', fontSize + 'px');
                sizerDiv.remove();
            }.bind(this), 1000);

            this.showPage('.scavenger-mainPage');
        }.bind(this);

        this.showMainQuestion = function(){
            container.find('.scavenger-mainPage-object-wrapper').hide();
            container.find('.scavenger-mainPage-object-buttons').hide();
            container.find('.scavenger-mainPage-question-wrapper').fadeIn();
            container.find('.scavenger-mainPage-question-buttons').show();

            TweenMax.fromTo(container.find('.scavenger-mainPage .scavenger-buttons button:nth-of-type(1)'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut, delay:0});
            TweenMax.fromTo(container.find('.scavenger-mainPage .scavenger-buttons button:nth-of-type(2)'), 0.5, {alpha:0, left:+400}, {alpha:1, left:0, ease:Back.easeOut, delay:0.3});
            createjs.Sound.play('scavenger-button-appear');

            if ( activeClue == 0 ) {
                createjs.Sound.play('scavenger-question-vo');
            }
        }.bind(this);

        this.showSuccess = function(){
            createjs.Sound.play("scavenger-reward");
            container.find('.scavenger-success-reward-text').html(settings.questions[activeClue].rewardText).typewriter();
            container.find('.scavenger-success-checklist li').slice(0, activeClue+1).addClass('completed');
            container.find('.scavenger-successPage').find('.scavenger-clue-number').text(activeClue + 1);

            // animations
            container.find('.scavenger-successPage-wrapper').hide().delay(500).slideDown(1000);
            TweenMax.fromTo(container.find('.scavenger-success-header'), 0.5, {scale:0}, {scale:1, delay:1.3});
            container.find('.scavenger-success-reward-text').hide().delay(1800).slideDown(400);
            TweenMax.fromTo(container.find('.scavenger-success-checklist li.completed'), 0.5, {scale:0}, {scale:1, delay:2.2});
            TweenMax.fromTo(container.find('.scavenger-success-checklist li:not(.completed)'), 0.5, {alpha:0}, {alpha:1, delay:2.7});
            TweenMax.fromTo(container.find('.scavenger-success-count'), 0.5, {alpha:0}, {alpha:1, delay:2.7});
            TweenMax.fromTo(container.find('.scavenger-nextButton'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut, delay:3});
            createjs.Sound.play('scavenger-screen-appear', {delay:500});
            createjs.Sound.play('scavenger-button-appear', {delay:3000});

            this.showPage('.scavenger-successPage');
        }.bind(this);

        this.showEnd = function(){
            createjs.Sound.play("scavenger-reward");
            container.find('.scavenger-endPage-ending').html(settings.end.endingText);

            // animations
            container.find('.scavenger-endPage-wrapper').hide().delay(500).slideDown(1000);
            TweenMax.fromTo(container.find('.scavenger-endPage-header'), 0.5, {scale:0}, {scale:1, delay:1.5});
            container.find('.scavenger-endPage-ending-scroller').hide().delay(2100).slideDown(500);
            var buttonTween = TweenMax.fromTo(container.find('.scavenger-endButton'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut}).pause();
            createjs.Sound.play('scavenger-screen-appear', {delay:500});

            // start typewriter and show button once it's done
            container.find('.scavenger-endPage-ending').typewriter(60, true, function(){
                buttonTween.resume();
                createjs.Sound.play('scavenger-button-appear');
            });

            // start VO
            createjs.Sound.play('scavenger-win');
            if ( settings.end.endingVO ) {
                createjs.Sound.play('scavenger-ending-vo', {delay: 2000});
            }

            this.music.paused=true;
            this.music.stop();

            this.showPage('.scavenger-endPage');
        }.bind(this);

        this.showHint = function(){
            container.find('.scavenger-hintImage').attr('src', assetsPath + settings.questions[activeClue].hintImage);
            this.showPage('.scavenger-hintPage');
        }.bind(this);

        this.showParentCheck = function(){
            container.find('.scavenger-parentPassword').val('');
            this.showPage('.scavenger-parentCheck');
        }.bind(this);

        this.showParentPage = function(){
            container.find('.scavenger-clue').html(settings.questions[activeClue].clue);
            container.find('.scavenger-hintImage').attr('src', assetsPath + settings.questions[activeClue].hintImage);
            this.showPage('.scavenger-parentPage');
        }.bind(this);

        /**
         * Start animations, sounds, etc.
         */
        this.start = function() {
            this.showPage('.scavenger-startPage');

            // animations
            container.find('.scavenger-startPage-wrapper').hide().delay(500).slideDown(1000);
            container.find('.scavenger-startPage-intro-scroller').hide().delay(1500).slideDown(400);
            var buttonTween = TweenMax.fromTo(container.find('.scavenger-startButton'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut}).pause();
            createjs.Sound.play('scavenger-screen-appear', {delay:500});

            // start typewriter and show button once it's done
            container.find('.scavenger-startPage-intro').typewriter(60, true, function(){
                buttonTween.resume();
                createjs.Sound.play('scavenger-button-appear');
            });

            // start music
            this.music = createjs.Sound.play('scavenger-music', {volume:0.2, loop:-1});

            // start VO
            if ( settings.start.introVO ) {
                createjs.Sound.play('scavenger-intro-vo', {delay: 2000});
            }

        };


        this.init();


    };

    return Module;

});


define('scavenger', ['scavenger/module'], function (main) { return main; });

