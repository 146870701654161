define('point-and-click/js/Cursor',[], function() {

    function Cursor(params, PCGame) { //var params = {target}
        var target = params.target;

        this.name = params.name;

        var states = [];
        var loopPoints = [];

        this.CursorClick = function CursorClick(event) {
            if ( event.isTouch )
                return;
            // cursor is within stage container, but needs to use screen units for positioning

            var point = {x: event.stageX, y: event.stageY};

            point = PCGame.stageContainer.globalToLocal(point.x, point.y);

            target.x = point.x;
            target.y = point.y;

            target.gotoAndPlay(states[1]);
        }.bind(this);

        this.CursorMove = function CursorMove(event) {
            if ( event.isTouch )
                return;

            var point = {x: event.stageX, y: event.stageY};

            point = PCGame.stageContainer.globalToLocal(point.x, point.y);

            target.x = point.x;
            target.y = point.y;

            this.Stop();
        }.bind(this);

        function handleTick(event) {
            var currentFrame = event.currentTarget.currentFrame;
            var loopPoint = loopPoints[1];
            if(currentFrame == loopPoint)
            {
                this.Stop();
            }
        }

        this.Stop = function () {
            if ( PCGame.activeCursor == this.name ) {
                target.gotoAndStop(states[1]);
            }
            else
                target.gotoAndStop(states[0]);
        }

        this.init = function () {
            target.cursor = this;
            target.mouseEnabled = false;
            states = $.map(target.labels, function(n){return n.label});
            var duration = target.timeline.duration;
            loopPoints = $.map(target.labels, function(n){return (n.position-1 + duration) % duration});
            loopPoints.push(loopPoints.shift());
            target.addEventListener("tick", handleTick.bind(this));
            target.loop = false;
            if(params.fps!=null)
                target.framerate = params.fps;
            this.Stop();
        }

        this.init();
    }

    return Cursor;
});

