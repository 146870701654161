define('js/view',['require','underscore','jquery','signals','tweenmax','dialog','preload','sound','typewriter','json!modules/module-intros.json','json!modules/module-outros.json','json!modules/config.json','json!config.json'],function(require) {
    var _ = require('underscore');
    var $ = require('jquery');
    var Signal = require('signals').Signal;
    var TweenMax = require('tweenmax');
    var Dialog = require('dialog');
    require('preload');
    require('sound');
    require('typewriter');
    var ModulesIntros = require('json!modules/module-intros.json');
    var ModulesOutros = require('json!modules/module-outros.json');
    var ModulesConfig = require('json!modules/config.json');
    var config = require('json!config.json');

    var View = function(){

        this.onModuleRequest = new Signal();
        this.onPostComplete = new Signal();
        this.onLoginSubmit = new Signal();
        this.onLogoutSubmit = new Signal();

        // these are exposed to the framework which allow a module to be loaded for the main view
        this.mainModuleName = 'unimind';
        this.mainModuleContainer = $('#container');

        var _mainModuleInstance = null; // the PCGame module
        var _unimind = null; // A direct reference to PCGame inside mainModuleInstance
        var _posts = [];
        var _currentPost = null;
        var _moduleModuleInstance = null;
        var _moduleContainer = null;
        var _moduleIntroSound = null;
        var _splashLoopSound = null;
        var _splashIsDone = false;
        var _didInteractiveLogin = false;
        var _loadingComplete = false;

        // INITIALIZE

        this.init = function(){
            $('#splash').fadeIn();

            // sizing / scaling
            function resize(){
                var scaleW = $(window).innerWidth() / 768;
                var scaleH = $(window).innerHeight() / 1024;
                var scale = Math.min(scaleW, scaleH);
                var width = 768;
                var height = 1024;
                $('#splash .container, #module-intro').css({
                    transform: 'scale('+scale+') translate3d(0,0,0)',
                    transformOrigin: '0 0',
                    width: 768,
                    height: 1024,
                    left: ($(window).innerWidth() - width*scale) / 2,
                    top: ($(window).innerHeight() - height*scale) / 2
                });
            }
            resize();
            $(window).resize(resize);

            // preload sounds
            var queue = new createjs.LoadQueue(true);
            queue.installPlugin(createjs.Sound);
            queue.loadManifest([
                {id:'splash-click', src:'click.mp3'},
                {id:'splash-error', src:'error.mp3'},
                {id:'splash-loading-complete', src:'loading-complete.mp3'},
                {id:'splash-welcome', src:'welcome.mp3'},
                {id:'splash-loop', src:'loop.mp3'}
            ], true, 'audio/splash/');
            queue.on('complete', _handleLoadingComplete, this);

            // register but don't preload a sound that doesn't get played for ages
            createjs.Sound.registerSound({src: 'audio/module-outro.mp3'});
            Dialog.initSounds();

            // DOM hookups
            $('#login-button').on('click', _.debounce(_handleLoginButtonClick.bind(this), 1000, true));
            $('#logout-button').on('click', _.debounce(_handleLogoutButtonClick.bind(this), 1000, true));
            $('#welcome-button').on('click', _.debounce(_handleWelcomeButtonClick.bind(this), 1000, true));

            $('#all-games-button').on('click', _.debounce(_handleAllGamesButtonClick.bind(this), 1000, true));
            $('#all-games-login form').on('submit', _.debounce(_handleAllGamesLoginSubmit.bind(this), 1000, true));
            $('#all-games-login .cancel').on('click', _.debounce(_handleAllGamesLoginCancel.bind(this), 1000, true));

            // show All Games button
            if(window.cordova) {
                if(cordova.platformId == "ios") {
                    $('#all-games-button').show();
                    $('#all-games-login form').attr('action', config.apiUrl + config.authParentPath);
                }
            }


            // start loading animation
            $('#loading').fadeIn();
            TweenMax.to($('#loading-spinner'), 1, {left:'100%', yoyo:true, repeat:-1, ease:'Power2.easeInOut'});
        };

        this.setMainModuleLoadingProgress = function(percentage){
            $('#loading-number').text(Math.ceil(percentage*100));
        };

        this.setMainModuleLoaded = function(moduleInstance){
            if ( moduleInstance ) {
                _mainModuleInstance = moduleInstance;
                _unimind = moduleInstance.PCGame;
            }

            if ( !_loadingComplete || !_mainModuleInstance ) {
                return;
            }

            this.mainModuleContainer.css("visibility", "visible");
            this.mainModuleContainer.css("pointer-events", "inherit");
            
            $('#loading').fadeOut();
            TweenMax.killTweensOf($('#loading-spinner'));

            createjs.Sound.play('splash-loading-complete');
            _splashLoopSound = createjs.Sound.play('splash-loop', {loop: -1});

            // Unimind scene hookups
            _unimind.interactables['launch'].on('click', _.debounce(_handleActionButtonClick.bind(this), 1000, true));
            _unimind.on('completed', function(){
                // The point and click game is done
                this.closeModule();
            }.bind(this));

            _unimind.interactables['launch'].getTarget().visible = false; // activate this again where there is available activity
        };

        this.showPostsLoading = function(){
        };

        this.hidePostsLoading = function(){
        };

        this.showLogin = function(){
            $('#welcome').hide();
            $('#login').fadeIn();
            //$('#login-input').focus();
        };

        this.skipLogin = function(){
            $('#splash').hide();

            _splashIsDone = true;
            _updateCurrentPost();

            if ( _splashLoopSound ) _splashLoopSound.stop();
        };

        this.hideLogin = function(userData){
            $('#login').fadeOut();
            $('#login-error').fadeOut();

            var nameIsSet = _(userData.properties).findWhere({property:'personalized'});
            var name = nameIsSet ? _(userData.properties).findWhere({property:'name'}).value : 'Agent';

            $('#welcome-name').text(name);
            $('#welcome').fadeIn();

            if ( _didInteractiveLogin ) {
                $('#welcome-button').hide();
                $('#logout-button').hide();
                window.setTimeout(_hideSplashAndStartThingsUp.bind(this), 1500);
            } else {
                $('#welcome-button').show();
                $('#logout-button').show();
            }

            createjs.Sound.play('splash-welcome');
            if ( _splashLoopSound ) _splashLoopSound.stop();
        };

        this.showLoginError = function(error){
            $('#login-error').fadeIn();

            createjs.Sound.play('splash-error');
        };

        this.showTheEnd = function(){
            $('#splash').fadeIn();
            $('#theend').fadeIn();
            createjs.Sound.play('splash-in');
        }.bind(this);

        this.addPosts = function(posts){
            _posts = posts.concat(_posts);
            _updateCurrentPost();
        };

        this.openModule = function(){

            $('#module-intro-button').css("visibility", "hidden");
            $('#module-intro-loading').css("visibility", "visible");

            _unimind.disableScrolling();//.scrollLeftButton.visible = _unimind.scrollRightButton.visible = false;
            _unimind.interactables['activity_frame'].setState(0);
            _unimind.interactables['activity_frame'].addEventListener("complete", OnActivityFrameComplete);

            var signal = this.onModuleRequest;
            var canvas = this.mainModuleContainer;

            _unimind.Pan({x:_unimind.interactables['activity_frame'].getTarget().x});
            setTimeout(function(){
                _unimind.Zoom(1.83);
                // for really really wide screens, pan again while zooming in
                _unimind.Pan({x:_unimind.interactables['activity_frame'].getTarget().x});
            }, 1000);

            setTimeout(function(){
                _unimind.stageContainer['activity_blackout'].alpha = 0;
                _unimind.stageContainer['activity_blackout'].visible = true;
                TweenMax.to(_unimind.stageContainer['activity_blackout'], 0.5, {alpha:0.75});
            }, 1000);

            function OnActivityFrameComplete () {
                _unimind.interactables['activity_frame'].removeEventListener("complete", OnActivityFrameComplete);

                setTimeout(function(){
                    window.location.hash = "module";
                    location.reload();
                    //window.onhashchange = function() {
                    //    location.reload();
                    //};
                }, 500);
            }
        };

        this.setModuleLoadingProgress = function(percentage){
        };

        this.setModuleLoaded = function(moduleInstance, moduleContainer){
        };

        this.closeModule = function(){
            this.onPostComplete.dispatch(_currentPost); // tell the server we've beat the game

            var outro = ModulesOutros[_currentPost.prop.player_module];

            var congrats = [].concat(outro.congrats); // turn congrats into an array even if it isn't
            var congratsIndex = 0;

            showNextCongratsDialog.call(this);

            function showNextCongratsDialog() {
                var dialog = new Dialog(congrats[congratsIndex].heading, congrats[congratsIndex].text, congrats[congratsIndex].button, congrats[congratsIndex].sound, function(){
                    dialog.hide();
                    congratsIndex++;
                    if ( congratsIndex < congrats.length ) {
                        setTimeout(showNextCongratsDialog.bind(this), 1000);
                    } else {
                        // fade out the main module, show the completion dialog
                        this.mainModuleContainer.fadeOut(3000);
                        $('#module-outro').delay(3500).fadeIn(2000);

                        $('#module-outro-heading').html(outro.prompt.heading);
                        $('#module-outro-text').html(outro.prompt.text);
                        $('#module-outro-image').attr('src', outro.prompt.image);

                        createjs.Sound.play('audio/module-outro.mp3', {delay:750});
                    }
                }.bind(this));

                dialog.show();
            }

            // on device, when we suspend the app after the activity is complete it should trigger a return to start
            document.addEventListener("pause", function(){
                $('#module-outro').hide();
            }, false);
            document.addEventListener("resume", function(){
                location.href = 'index.html';
            }, false);
        };

        this.showVersion = function(version){
            $('#version-text').text(version);
        };

        this.showAppStore = function(url){
            $('#app-store').show().find('a').attr('href', url);
        };

        var _hideSplashAndStartThingsUp = function() {
            $('#welcome').fadeOut(500);

            $('#splash').delay(500).fadeOut(500, function () {
                _splashIsDone = true;
                _updateCurrentPost();
            });
        };

        var _handleLoadingComplete = function(){
            _loadingComplete = true;
            this.setMainModuleLoaded();
        };

        var _updateCurrentPost = function(){
            if ( !_splashIsDone ) {
                return;
            }

            var currentPost = _(_posts).first();
            if ( currentPost != _currentPost) {
                _currentPost = currentPost;

                // the adventure is over
                if ( _currentPost.node_script_id == 'message-end' ) {
                    this.showTheEnd();
                    return;
                }

                if ( _currentPost.prop ) {
                    var module = _currentPost.prop.player_module;

                    function startUnimind() {
                        if ( _currentPost.prop.player_module != 'unimind' ) {

                            _unimind.interactables['launch'].getTarget().visible = true;

                            // disable standard unimind gameplay
                            _unimind.inventoryContainer.visible = false;
                            _unimind.disablePickups = true;
                            for ( i in _unimind.collectables ) {
                                _unimind.collectables[i].collected = true;
                            }
                            _unimind.interactables['reel_pickup'].getTarget().visible = false;
                            _unimind.interactables['pipe_pickup'].getTarget().visible = false;
                            _unimind.interactables['punchcard_pickup'].getTarget().visible = false;
                            
                            _unimind.startMessage = 'general-activity';
                        }
                        else {
                            _unimind.loadAllMessageSounds();
                        }
                        _unimind.start();
                    }
                    
                    // preload relevant intro/outro
                    var sounds = [].concat( ModulesIntros[module].sound ).concat( _([].concat(ModulesOutros[module].congrats)).pluck('sound') ).filter(function(e){return e});
                    if ( sounds.length > 0 ) {
                        var queue = new createjs.LoadQueue(true);
                        queue.installPlugin(createjs.Sound);
                        sounds.forEach(function(s){ queue.loadFile({src:s}); });
                        queue.on('complete', startUnimind, this);
                    } else {
                        startUnimind.call(this);
                    }


                } else {
                    _unimind.startMessage = 'general-activity';
                    _unimind.start();
                }
            }
        }.bind(this);

        var _handleLoginButtonClick = function(){
            _didInteractiveLogin = true;

            var code = $('#login-input').val();

            if ( code == '000' ) {
                $('#all-games-menu').show();
                return;
            }

            this.onLoginSubmit.dispatch(code);
            createjs.Sound.play('splash-click');
        };

        var _handleLogoutButtonClick = function(){
            this.onLogoutSubmit.dispatch();
        };

        var _handleWelcomeButtonClick = function(){
            createjs.Sound.play('splash-welcome');
            _hideSplashAndStartThingsUp.call(this);
        };

        var _handleActionButtonClick = function(){
            this.openModule();
        };

        var _handleAllGamesButtonClick = function(){
            $('#all-games-login').show();
        };

        var _handleAllGamesLoginSubmit = function(e){
            e.preventDefault();
            $.post($(e.target).attr('action'), $(e.target).serialize())
                .done(function() {
                    $('#all-games-login').hide();
                    $('#all-games-menu').show();
                }.bind(this))
                .fail(function() {
                    alert("Incorrect password.");
                });
        };

        var _handleAllGamesLoginCancel = function(){
            $('#all-games-login').hide();
        };

        window.view = this;
    };

    return View;
});

