(function(root) {
define("mobiscroll.widget.imaginaryfriends", ["jquery","mobiscroll"], function() {
  return (function() {
(function ($) {

    var themes = $.mobiscroll.themes,
        theme = {
            display: 'inline',
            dateOrder: 'MMdyy',
            rows: 3,
            height: 50,
            headerText: false,
            showLabel: false,
            btnWidth: false,
            selectedLineHeight: true,
            selectedLineBorder: 1,
            useShortLabels: true,
            deleteIcon: 'backspace3',
            //checkIcon: 'ion-ios7-checkmark-empty',
            //btnCalPrevClass: 'mbsc-ic mbsc-ic-arrow-left5',
            //btnCalNextClass: 'mbsc-ic mbsc-ic-arrow-right5',
            btnPlusClass: 'if-upbutton',
            btnMinusClass: 'if-downbutton',
        };

    themes.imaginaryfriends = theme;

})(jQuery);


  }).apply(root, arguments);
});
}(this));

