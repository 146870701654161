/**
 * In this file, you should load all the libraries required by all the modules used in this site, and define the module packages.
 */

require.config({
    shim: {
        easel: {
            exports: 'createjs'
        },
        jquery:{
            exports: ["jQuery","$"]
        },
        tweenmax: {
            exports: 'TweenMax'
        },
        mobiscroll:{
            deps: ['jquery']
        },
        'mobiscroll.widget':{
            deps: ['jquery','mobiscroll']
        },
        'mobiscroll.widget.imaginaryfriends':{
            deps: ['jquery','mobiscroll']
        },
        'mobiscroll.scroller':{
            deps: ['jquery','mobiscroll','mobiscroll.widget']
        },
        'mobiscroll.image':{
            deps: ['jquery','mobiscroll','mobiscroll.listbase']
        },
        'mobiscroll.listbase':{
            deps: ['jquery','mobiscroll']
        },
        'MarkerWithLabel': {
            deps: ['googlemaps'],
            exports: 'MarkerWithLabel'
        },
        'webgl': {
            deps: ['easel']
        }
    },
    baseUrl: '.',
    paths: {
        jquery: 'lib/bower_components/jquery/dist/jquery',
        underscore: 'lib/bower_components/underscore/underscore',
        underscoreString: 'lib/bower_components/underscore.string/dist/underscore.string.min',
        signals: 'lib/bower_components/signals/dist/signals.min',
        text: 'lib/bower_components/requirejs-text/text',
        async: 'lib/bower_components/requirejs-plugins/src/async',
        json: 'lib/bower_components/requirejs-plugins/src/json',
        easel: 'lib/bower_components/easeljs/lib/easeljs-0.8.2.min',
        tween: 'lib/bower_components/createjs-tweenjs/lib/tweenjs-0.6.2.min',
        preload: 'lib/bower_components/PreloadJS/lib/preloadjs-0.6.2.min',
        sound: 'lib/bower_components/SoundJS/lib/soundjs-0.6.2.min',
        webgl: 'lib/bower_components/easeljs/lib/webgl-0.8.2.min',
        mobiscroll: 'lib/bower_components/mobiscroll/js/mobiscroll.core',
        'mobiscroll.widget': 'lib/bower_components/mobiscroll/js/mobiscroll.widget',
        'mobiscroll.widget.imaginaryfriends': 'lib/plugins/mobiscroll.widget.imaginaryfriends',
        'mobiscroll.scroller': 'lib/bower_components/mobiscroll/js/mobiscroll.scroller',
        'mobiscroll.listbase': 'lib/bower_components/mobiscroll/js/mobiscroll.listbase',
        'mobiscroll.image': 'lib/bower_components/mobiscroll/js/mobiscroll.image',
        tweenmax:  'lib/bower_components/gsap/src/minified/TweenMax.min',
        MarkerWithLabel: 'lib/bower_components/markerwithlabel/dist/markerwithlabel.min',
        dialog: 'lib/plugins/dialog',
        typewriter: 'lib/plugins/typewriter'
    },
    map: {
        '*': {
            googlemaps: 'async!http://maps.google.com/maps/api/js?sensor=false'
        }
    },
    packages: [
        { name: "framework", location: "lib/framework", main: "main" },
        { name: "point-and-click", location: "lib/modules-lib/point-and-click", main: "module" },
        { name: "jigsaw", location: "lib/modules-lib/jigsaw", main: "module" },
        { name: "scavenger", location: "lib/modules-lib/scavenger", main: "module" },
        { name: "hotcold", location: "lib/modules-lib/hotcold", main: "module" },
        { name: "mastermind", location: "lib/modules-lib/mastermind", main: "module" },
        { name: "flambe", location: "lib/modules-lib/flambe", main: "module" }
    ]
});

require(['jquery', 'framework', 'js/view', 'js/view-module', 'easel', 'sound', 'tween', 'preload', 'webgl'], function($, framework, View, ViewModule){
    $(function(){

        // CRAZY BULLSHIT
        if ( /(iPhone|iPad)/i.test(navigator.userAgent) && /9_2/i.test(navigator.userAgent) ) {
            var AudioCtor = window.AudioContext || window.webkitAudioContext;
            window.webkitAudioContext = function createAudioContext (desiredSampleRate) {
                desiredSampleRate = typeof desiredSampleRate === 'number'
                    ? desiredSampleRate
                    : 44100
                var context = new AudioCtor()

                if (context.sampleRate !== desiredSampleRate) {
                    var buffer = context.createBuffer(1, 1, desiredSampleRate)
                    var dummy = context.createBufferSource()
                    dummy.buffer = buffer
                    dummy.connect(context.destination)
                    dummy.start(0)
                    dummy.disconnect()
                    context.close() // dispose old context
                    context = new AudioCtor()
                }
                return context
            };
        }
        // END CRAZY BULLSHIT

        var view;

        if(window.location.hash=="#module") {
            view = new ViewModule();
        }
        else {
            view = new View();
        }

        view.init();
        framework.start(view);

        // for debugging
        window.view = view;
    });
});

define("main", function(){});

