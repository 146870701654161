define('js/view-module',['require','underscore','jquery','signals','tweenmax','preload','sound','typewriter','dialog','json!modules/module-intros.json','json!modules/module-outros.json'],function(require) {
    var _ = require('underscore');
    var $ = require('jquery');
    var Signal = require('signals').Signal;
    var TweenMax = require('tweenmax');
    require('preload');
    require('sound');
    require('typewriter');
    var Dialog = require('dialog');
    var ModulesIntros = require('json!modules/module-intros.json');
    var ModulesOutros = require('json!modules/module-outros.json');

    var View = function(){

        this.onModuleRequest = new Signal();
        this.onPostComplete = new Signal();
        this.onLoginSubmit = new Signal();
        this.onLogoutSubmit = new Signal();


        var _posts = [];
        var _currentPost = null;
        var _moduleModuleInstance = null;
        var _moduleContainer = null;
        var _moduleIntroSound = null;

        // INITIALIZE

        this.init = function(){

            // #container normally holds unimind, stick a static background in there instead
            $('#container').css({
                backgroundImage: 'url(img/standalone-bg.jpg)',
                backgroundSize: 'auto 100%',
                backgroundPosition: 'center center'
            });

            window.onhashchange = function() { // i.e. back button functionality
                location.reload();
            };

            // sizing / scaling
            function resize(){
                var scaleW = $(window).innerWidth() / 768;
                var scaleH = $(window).innerHeight() / 1024;
                var scale = Math.min(scaleW, scaleH);
                var width = 768;
                var height = 1024;
                $('#splash .container, #module-intro').css({
                    transform: 'scale('+scale+') translate3d(0,0,0)',
                    transformOrigin: '0 0',
                    width: 768,
                    height: 1024,
                    left: ($(window).innerWidth() - width*scale) / 2,
                    top: ($(window).innerHeight() - height*scale) / 2
                });
            }
            resize();
            $(window).resize(resize);

            // register but don't preload a sound that doesn't get played for ages
            createjs.Sound.registerSound({src: 'audio/splash/click.mp3'});
            createjs.Sound.registerSound({src: 'audio/splash/welcome.mp3'});
            createjs.Sound.registerSound({src: 'audio/module-outro.mp3'});
            Dialog.initSounds();

            // DOM hookups
            $('#module-intro-button').on('click', _.debounce(_handleModuleStartButtonClick.bind(this), 1000, true));
        };

        this.showPostsLoading = function(){
        };

        this.hidePostsLoading = function(){
        };

        this.showLogin = function(){
        };

        this.skipLogin = function(){
        };

        this.hideLogin = function(userData){
        };

        this.showLoginError = function(error){
        };

        this.addPosts = function(posts){
            _posts = posts.concat(_posts);
            _updateCurrentPost.call(this);
        };

        this.openModule = function(){
            $('#module-intro').show();

            $('#module-intro-button').hide();

            var message = ModulesIntros[_currentPost.prop.player_module];

            $('#module-intro-text').html(message.text).typewriter(50);

            if ( message.sound ) {
                _moduleIntroSound = createjs.Sound.play(message.sound);
            }

            this.onModuleRequest.dispatch(_currentPost);
        };

        this.setModuleLoadingProgress = function(percentage){
            $('#module-intro-loading-number').text(Math.ceil(percentage*100));
        };

        this.setModuleLoaded = function(moduleInstance, moduleContainer){
            _moduleModuleInstance = moduleInstance;
            _moduleContainer = moduleContainer;

            $('#module-intro-button').fadeIn();
        };

        this.closeModule = function(){
            this.onPostComplete.dispatch(_currentPost); // tell the server we've beat the game

            var outro = ModulesOutros[_currentPost.prop.player_module];

            var congrats = [].concat(outro.congrats); // turn congrats into an array even if it isn't
            var congratsIndex = 0;

            showNextCongratsDialog.call(this);

            function showNextCongratsDialog() {
                var dialog = new Dialog(congrats[congratsIndex].heading, congrats[congratsIndex].text, congrats[congratsIndex].button, congrats[congratsIndex].sound, function(){
                    dialog.hide();
                    congratsIndex++;
                    if ( congratsIndex < congrats.length ) {
                        setTimeout(showNextCongratsDialog.bind(this), 1000);
                    } else {
                        // fade out the main module, show the completion dialog
                        $('#container').fadeOut(3000);
                        _moduleContainer.fadeOut(3000);
                        $('#module-outro').delay(3500).fadeIn(2000);

                        $('#module-outro-heading').html(outro.prompt.heading);
                        $('#module-outro-text').html(outro.prompt.text);
                        $('#module-outro-image').attr('src', outro.prompt.image);

                        createjs.Sound.play('audio/module-outro.mp3', {delay:750});
                    }
                }.bind(this));

                dialog.show();
            }

            // on device, when we suspend the app after the activity is complete it should trigger a return to start
            document.addEventListener("pause", function(){
                $('#module-outro').hide();
            }, false);
            document.addEventListener("resume", function(){
                location.href = 'index.html';
            }, false);
        };

        var _updateCurrentPost = function(){
            var currentPost = _(_posts).first();
            if ( currentPost != _currentPost) {
                _currentPost = currentPost;

                if ( _currentPost.prop ) {
                    var module = _currentPost.prop.player_module;

                    // preload relevant intro/outro
                    var sounds = [].concat( ModulesIntros[module].sound ).concat( _([].concat(ModulesOutros[module].congrats)).pluck('sound') ).filter(function(e){return e});
                    if ( sounds.length > 0 ) {
                        var queue = new createjs.LoadQueue(true);
                        queue.installPlugin(createjs.Sound);
                        sounds.forEach(function(s){ queue.loadFile({src:s}); });
                        queue.on('complete', this.openModule, this);
                    } else {
                        this.openModule();
                    }
                }
            }
        };

        var _handleModuleStartButtonClick = function(){
            if ( _moduleIntroSound ) _moduleIntroSound.paused=true;
            _moduleContainer.css("visibility", "visible");
            _moduleContainer.css("pointer-events", "inherit");
            _moduleContainer.css("z-index", "initial");
            $('#module-intro').fadeOut();
            _moduleContainer.hide().fadeIn(1000);
            _moduleModuleInstance.start();

            createjs.Sound.play('audio/splash/click.mp3');
            createjs.Sound.play('audio/splash/welcome.mp3');
        };

    };

    return View;
});

