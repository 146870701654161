define('framework/PostController',['require','underscore','jquery','signals','json!config.json'],function(require){
    var _ = require('underscore');
    var $ = require('jquery');
    var signals = require('signals');
    var config = require('json!config.json');

    var _confirmPostUrl = config.apiUrl + config.confirmPath;
    var _feedUrl = config.apiUrl + config.feedPath;
    var _site = config.site;
    var _previewPost = null;

    var PostController = function(){

        this.onPostsLoaded = new signals.Signal();
        this.onAuthError = new signals.Signal();

        var _posts = [];

        this.refresh = function() {
            $.get(_feedUrl, function(posts){
                _handlePosts(posts);
            }.bind(this))
                .fail(_onRequestFail);
        };

        this.loadPreviewPost = function(post) {
            _previewPost = post;
            _handlePosts([_previewPost]);
        };

        this.confirmPost = function(post) {
            if ( _previewPost ) {
                // when in preview mode, we don't send anything back to the server
                return;
            }
            if ( !post.node_script_id ) {
                return;
            }

            var data = {
                site: _site,
                post: post.node_script_id
            }

            $.post(_confirmPostUrl, data, function(){
                this.refresh();
            }.bind(this))
                .fail(_onRequestFail);
        };

        var _handlePosts = function(posts){
            var newPosts = _(posts).reject(function(p){return _.chain(_posts).pluck('id').contains(p.id).value() });
            _posts = newPosts.concat(_posts);
            this.onPostsLoaded.dispatch(newPosts);
        }.bind(this);

        var _onRequestFail = function(xhr){
            if ( xhr.status == 401 ) {
                this.onAuthError.dispatch();
            }
        }.bind(this);

    }

    return PostController;
});



