define('typewriter',['require','jquery'],function(require){
    var $ = require('jquery');

    $.fn.typewriter = function(delay, useDisplayNone, onComplete) {
        delay = delay || 40;

        // convert \n to <br> and then <br> to <p>
        this.html( (this.html() + '').replace(/\n/g, '<br>') );
        this.contents().filter(function() {return this.nodeType === 3;}).wrap("<p></p>");
        this.contents().filter('br+br').replaceWith('<p>&nbsp;</p>'); // convert double line breaks into an empty p block
        this.contents().filter('br').remove(); // remove the rest

        if ( this.children('p').length ) {
            var elements = this.children('p');
        }
        else {
            var elements = this;
        }

        elements.each(function(){
            var css = useDisplayNone ? {display: 'none'} : {visibility: 'hidden'};
            var invisible = $(this).contents().first().wrap('<span></span>').parent().css(css);
            var visible = $('<span>').insertBefore(invisible);
        });

        var elementIndex = 0;
        var interval = setInterval(type.bind(this), delay);

        function type(){
            var el = elements.eq(elementIndex);
            var visible = el.children().eq(0);
            var invisible = el.children().eq(1);
            if ( invisible.text().length ) {
                visible.text(visible.text() + invisible.text().charAt(0));
                invisible.text(invisible.text().substr(1));
            }
            else {
                elementIndex++;
                if ( elementIndex < elements.length ) {
                    type();
                }
                else {
                    clearInterval(interval);
                    if ( onComplete ) {
                        onComplete();
                    }
                }
            }
        }
    }

});
