define('framework/AuthController',['require','underscore','jquery','signals','json!config.json'],function(require) {
    var _ = require('underscore');
    var $ = require('jquery');
    var Signal = require('signals').Signal;
    var config = require('json!config.json');

    var _authUrl = config.apiUrl + config.authPath;

    var AuthController = function () {

        this.onAuth = new Signal();
        this.onAuthError = new Signal();

        Object.defineProperty(this, 'login', {
            get: function() { return window.localStorage.getItem(config.site+'login') },
            set: function(value) { window.localStorage.setItem(config.site+'login', value) }
        });

        this.auth = function(login) {
            if ( login ) {
                this.login = login;
            }
            $.post(_authUrl)
                .done(function(xhr){
                    this.onAuth.dispatch(xhr);
                }.bind(this))
                .fail(function(xhr){
                    this.onAuthError.dispatch(JSON.parse(xhr.responseText));
                }.bind(this));
        };

        this.logout = function() {
            this.login = null;
        };

    };

    return AuthController;
});
