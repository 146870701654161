define('point-and-click/module',['jquery','./js/PCGame','easel','tween','preload','sound'],function($, game){


    
    var Module = function Module(element, config, assetsPath, loadedCallback, loadingProgressCallback, instructionsCallback, winCallback) {
        /**
         * Do all initialization here.
         */

        this.PCGame = null; //exposed this to clients
        
        var settings = {};
        var canvas;
        var stage;
        var bg;
        
        var moduleHeight = 2048;
        var moduleWidth = 1536;

        var n;

        function imagesLoaded () {
            
            loadedCallback.call();

        }
        
        this.init = function() {
            
            settings = config;
            //console.log(settings);
             
            canvas = document.createElement("canvas");
            $(canvas).addClass("point-and-click-canvas");
            element.appendChild(canvas);

            stage = new createjs.Stage(canvas);

            // sizing/scaling
            var hidpi = window.devicePixelRatio > 1;
            var canvasScale = hidpi ? 2 : 1;

            function resize(){
                //TODO: using window instead of an element as a hack for showing this inside an element that isn't onscreen (and thus doesn't have a size yet).
                //A better solution would be to make sure that the element has a size before coming in here, or provide a way to specify it.
                canvas.width = window.innerWidth; //element.clientWidth;
                canvas.height = window.innerHeight; //element.clientHeight;
                canvas.style.width = window.innerWidth; //element.clientWidth;
                canvas.style.height = window.innerHeight; //element.clientHeight;

                stage.scaleX = stage.scaleY = canvas.height / 2048;
                
                if ( hidpi ) {
//                    canvas.width *= 2;
//                    canvas.height *= 2;
                    
                // load 2x assets on retina screens only when window height is greater than 512 pixels
                    if(stage.scaleY >= 0.25) {
                        //console.log("hidpi");
//                        settings.scale = 1;
                    }
                }
                
            }
            resize();
            $(window).resize(resize);
            // end sizing/scaling

            this.PCGame = new game(settings, stage, assetsPath, imagesLoaded, loadingProgressCallback, winCallback);
            
        }
        
        /**
         * Start animations, sounds, etc.
         */
        this.start = function() {
            this.PCGame.start();
        }

        this.showHelp = function(){
            console.log('help');
            instructionsCallback.call();
        }
        
        this.init();
    }

    return Module;

});


define('point-and-click', ['point-and-click/module'], function (main) { return main; });

