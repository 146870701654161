define('framework/ModuleController',['require','underscore','jquery','signals','json!modules/config.json','json!config.json'],function(require) {
    var _ = require('underscore');
    var $ = require('jquery');
    var signals = require('signals');
    var modulesConfig = require('json!modules/config.json');
    var config = require('json!config.json');

    var _parentAuthUrl = config.apiUrl + config.authParentPath;

    var ModuleController = function () {

        // {moduleInstanceName: {module: module_class_reference, config: config_object}
        var _loadedActivities = {};

        this.handleNewPosts = function(posts) {
            _(posts).each(function(post){
                if ( post.prop ) {
                    this.loadModule(post.prop.player_module, post.prop.player_module_config);
                }
            }.bind(this));
        }

        this.loadModule = function(moduleName, extraConfig, onReady) {
            onReady = onReady || function(){};

            if ( _loadedActivities.hasOwnProperty(moduleName) ) {
                onReady.call(this, _loadedActivities[moduleName]);
                return;
            }

            var modulePath = modulesConfig[moduleName]['path'];
            var moduleLib = modulesConfig[moduleName]['lib'];
            extraConfig = extraConfig || {};

            require([moduleLib, 'json!modules/' + modulePath + '/config.json'], function (module, config) {

                config = $.extend(true, config, extraConfig);

                if ( config.hasOwnProperty('parentAuthUrl') ) {
                    config.parentAuthUrl = _parentAuthUrl;
                }

                _loadedActivities[moduleName] = {
                    moduleLibClass: module,
                    moduleLibName: moduleLib,
                    config: config
                };

                onReady.call(this, _loadedActivities[moduleName]);
            });
        }

        this.initModule = function (moduleName, container, onLoaded, onLoadingProgress, onCompleted) {

            if ( !_loadedActivities[moduleName] ) {
                console.error("Module " + moduleName + " hasn't been loaded yet, so it won't be started.");
                return;
            }

            var moduleLibClass = _loadedActivities[moduleName].moduleLibClass;
            var moduleLibName = _loadedActivities[moduleName].moduleLibName;
            var config = _loadedActivities[moduleName].config;

            //var fullModuleLibPath = 'modules-lib/' + moduleLibName;
            var fullModulePath = 'modules/' + modulesConfig[moduleName]['path'];

            //probably just add an instance-specific top-level selector on instance stylesheets, and add that class/id onto the container
            //var currentModuleCSS = $('<link>').attr({
            //    rel: 'stylesheet',
            //    href: fullModuleLibPath + '/module.css'
            //});
            //$('head').append(currentModuleCSS);
            //var currentModuleInstanceCSS = $('<link>').attr({
            //    rel: 'stylesheet',
            //    href: fullModulePath + '/module.css'
            //});
            //$('head').append(currentModuleInstanceCSS);

            onResize();
            $(window).resize(onResize);

            function onResize() {
                if ( modulesConfig[moduleName].fullscreen ) {
                    container.css({
                        position: 'absolute',
                        transform: 'translate3d(0,0,0)',
                        width: $(window).width(),
                        height: $(window).height()
                    });
                }
                else {
                    var winWidth = $(window).innerWidth();
                    var winHeight = $(window).innerHeight();
                    var elemWidth = 768;
                    var elemHeight = 1024;
                    var scaleX = winWidth / elemWidth;
                    var scaleY = winHeight / elemHeight;
                    var scale = Math.min(Math.min(scaleX, scaleY), 1);
                    container.css({
                        position: 'absolute',
                        transform: 'scale('+scale+') translate3d(0,0,0)',
                        transformOrigin: '0 0',
                        left: (winWidth-scale*elemWidth)/2,
                        top: (winHeight-scale*elemHeight)/2,
                        width: elemWidth,
                        height: elemHeight
                    });
                }
            }

            // add css classes for customization
            container.addClass('module-container');
            if ( modulesConfig[moduleName].fullscreen ) {
                container.addClass('module-container-fullscreen')
            }

            // LOAD THE MODULE!
            // we actually want to do this after sizing code, in case the module uses its container size for something

            if (!jQuery.contains(document, container[0])) {
                container.css("visibility", "hidden");
                container.css("pointer-events", "none");
                container.css("z-index", -1);
                $('body').append(container);
            }
            
            var onInstructions = function(){}; // this is a stopgap for the solution for the fact that module defs still require on onInstructions callback, even though we've abandoned this concept

            var moduleInstance = new moduleLibClass(container.get(0), config, fullModulePath, onLoaded, onLoadingProgress, onInstructions, onCompleted);
            moduleInstance.config = config; // ensure external functions have access to config information

            window.module = moduleInstance; // allow console access to module

            return moduleInstance;
        }

    };

    return ModuleController;
});



