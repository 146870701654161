define('mastermind/module',['jquery', 'underscore', 'dialog','easel','mobiscroll','mobiscroll.image','mobiscroll.widget','mobiscroll.widget.imaginaryfriends','mobiscroll.scroller','mobiscroll.listbase','tweenmax','preload','sound'],function($, _, Dialog){

    var Module = function Mastermind(element, config, assetsPath, loadedCallback, loadingProgressCallback, instructionsCallback, winCallback) {
        assetsPath += assetsPath.length ? '/' : '';
        
        /**
         * Do all initialization here.
         */

        var settings = {};
        var guessesAndResponses = [];
        var guesses = 0;
        var patternLength;
        var canvas;
        var stage;
        var WRONG_COLOUR_WRONG_LOCATION = 0;
        var RIGHT_COLOUR_WRONG_LOCATION = 1;
        var RIGHT_COLOUR_RIGHT_LOCATION = 2;
        var selects;
        var guessButton;
        var revealPanel;
        var actualPattern;
        var losepopup;
        var winpopup;
        var hintContainer;
        var gameOver = false;

        var correctString;
        var almostCorrectString;
        var correctText;
        var almostCorrectText;
        var hintText;

        var winText;

        var revealElements=[];

        var guessesRemaining;

        var imageWidth = 83;
        var imageHeight = 96;
        var xMargin = 0;
        var yMargin = 0;

        var moduleWidth = 768;
        var moduleHeight = 1024;

        var tutorialprogress = -1;

        var firstSound;
        var secondSound;
        var loseSound;

        this.init = function() {
            //element.innerHTML = "element";
            settings = config;

            this.settings = settings;
            //            console.log(settings);

            xMargin=settings.xMargin || xMargin;
            yMargin=settings.yMargin || yMargin;

            imageWidth = settings.imageWidth || imageWidth;
            imageHeight = settings.imageHeight || imageHeight;

            patternLength = settings.actualPattern ? settings.actualPattern.length : settings.patternLength;
            actualPattern = settings.actualPattern || newPattern(patternLength);

            canvas = document.createElement("canvas");
            canvas.width=moduleWidth;
            canvas.height=moduleHeight;

            //$(element).css("width",moduleWidth);
            //$(element).css("height",moduleHeight);

            $(canvas).addClass("mastermind-canvas");
            element.appendChild(canvas);

            //canvas.context.imageSmoothingEnabled=false;
            //canvas.context.webkitImageSmoothingEnabled=false;
            //canvas.context.mozImageSmoothingEnabled=false;

            stage = new createjs.Stage(canvas);

            this.stage=stage;

            var scrollerdiv = document.createElement("div");
            $(scrollerdiv).addClass("mastermind-scrollerdiv");
            $(scrollerdiv).css("left", moduleWidth/2 - ((patternLength/2) * (imageWidth + xMargin) - xMargin/2));

            for(var i=0; i<patternLength; i++){
                var selectElement = document.createElement("ul");
                for(var j=0; j<settings.optionImages.length; j++){
                    var optionElement = document.createElement("li");
                    optionElement.dataset.val=j;
                    var imgElement = document.createElement("img");
                    imgElement.setAttribute("src",assetsPath+settings.optionImages[j]);
                    optionElement.appendChild(imgElement);

                    //                    if(settings.showLabels){
                    //                        var pElement = document.createElement("p");
                    //                        var textNode = document.createTextNode(settings.options[j]);
                    //                        optionElement.appendChild(textNode);
                    //                    }

                    selectElement.appendChild(optionElement);
                    // selectElement.setAttribute("id","select"+i);
                    selectElement.setAttribute("class","mastermind-select");

                }
                scrollerdiv.appendChild(selectElement);
                $(selectElement).css("margin-left", "50px");
            }
            element.appendChild(scrollerdiv);

            guessButton = document.createElement("Button");
            //guessButton.innerHTML="GUESS";

            $(guessButton).addClass("mastermind-guessButton");
            $(guessButton).on("click", _.debounce(this.guessFromSelects.bind(this), 1000, true));

            revealPanel = document.createElement("div");
            $(revealPanel).addClass("mastermind-toppanel");
            var imgElement = document.createElement("img");
            imgElement.setAttribute("src",assetsPath+settings.answerCover);
            revealPanel.appendChild(imgElement);
            element.appendChild(revealPanel);

            for(var i=0; i<settings.tutorialmessages.length; i++){

                var tutorialPopup = document.createElement("div");
                $(tutorialPopup).addClass("mastermind-tutorial mastermind-tutorial-"+i);
                var text = document.createElement("p");
                text.innerHTML = settings.tutorialmessages[i];
                tutorialPopup.appendChild(text);
                //
                //                var button = document.createElement("span");
                //                button.innerHTML = "ok";
                //                tutorialPopup.appendChild(button);

                $(tutorialPopup).on("click", this.showNextTutorial.bind(this));
                element.appendChild(tutorialPopup);

            }

            selects = $(element).find('.mastermind-select');


            if(settings.maxGuesses!=0){
                guessesRemaining = new createjs.Text("remaining: " + (settings.maxGuesses-guesses).toString(), "44px popregular", "#0bff00");
                guessesRemaining.x = moduleWidth-15;
                guessesRemaining.y = moduleHeight-47;
                guessesRemaining.textAlign = "right";

                stage.addChild(guessesRemaining);
            }

            var queue = new createjs.LoadQueue();
            queue.installPlugin(createjs.Sound);

            queue.on("complete", this.imagesLoaded, this);
            if ( loadingProgressCallback ) {
                queue.on("progress", function(e){ loadingProgressCallback.call(null, e.progress)});
            }

            for(var i=0; i<settings.optionImages.length; i++){
                queue.loadFile(assetsPath+settings.optionImages[i]);
            }
            queue.loadFile(assetsPath+settings.background);
            queue.loadFile(assetsPath+settings.answerCover);
            queue.loadFile(assetsPath+settings.responsebg);
            queue.loadFile(assetsPath+settings.hintbg);

            queue.loadFile({src:assetsPath+settings.clickSound, id:"mastermind-buttonClick"});
            queue.loadFile({src:assetsPath+settings.guessButtonSound, id:"mastermind-guessClick"});
            queue.loadFile({src:assetsPath+settings.winSound, id:"mastermind-winSound"});
            queue.loadFile({src:assetsPath+settings.loseSound, id:"mastermind-loseSound"});
            queue.loadFile({src:assetsPath+settings.loseVO, id:"mastermind-loseVO"});
            queue.loadFile({src:assetsPath+settings.music, id:"music"});
            
            queue.loadFile(assetsPath+settings.noduplicateshintaudio);

            for(var i=0; i<settings.right_place_audios.length; i++){
                if(settings.right_place_audios[i]!=null)
                    queue.loadFile(assetsPath+settings.right_place_audios[i]);
            }
            for(var i=0; i<settings.wrong_place_audios.length; i++){
                if(settings.wrong_place_audios[i]!=null)
                    queue.loadFile(assetsPath+settings.wrong_place_audios[i]);
            }
            for(var i=0; i<settings.hint_audios.length; i++){
                if(settings.hint_audios[i]!=null)
                    queue.loadFile(assetsPath+settings.hint_audios[i]);
            }
            for(var i=0; i<settings.tutorialaudios.length; i++){
                if(settings.tutorialaudios[i]!=null)
                    queue.loadFile(assetsPath+settings.tutorialaudios[i]);
            }
            
            // insert css and preload images in it
            $('head').append($('<link>').attr({rel: 'stylesheet', href: assetsPath + 'module.css'}));
            $.get(assetsPath+'module.css', function(response){
                var matches = [];
                response.match(/url\([^)]+\)/g).forEach(function(match){
                    match = match.match(/url\(['"]?(.*?)['"]?\)/)[1];
                    if ( !_(matches).contains(match) ) {
                        queue.loadFile(assetsPath + match);
                        matches.push(match);
                    }
                });
            });

            // createjs.Sound.addEventListener("fileload", createjs.proxy(this.loadHandler, (this)));
        }

        /**
         * Start animations, sounds, etc.
         */
        this.start = function() {
            this.music = createjs.Sound.play('music', {loop: -1, volume:0.3});
            this.showNextTutorial();
            TweenLite.from(revealPanel,1,{y:"-150"});
        }

        this.imagesLoaded = function(){
            $(selects).mobiscroll().image({
                theme: 'imaginaryfriends',     // Specify theme like: theme: 'ios' or omit setting to use default
                mode: 'mixed',       // Specify scroller mode like: mode: 'mixed' or omit setting to use default
                display: 'inline', // Specify display mode like: display: 'bottom' or omit setting to use default
                showInput: false,
                enhance: false,
                labels: settings.options,
                //inputClass: 'hideme valueField',
                defaultValue: 0,
                showLabel: false,
                rows:1,
                // layout: 'liquid',
                height:imageHeight,
                fixedWidth:imageWidth + xMargin,
                onChange: function (valueText, inst) {
                    createjs.Sound.play("mastermind-buttonClick",createjs.Sound);
                },
                onValueTap: function(item, inst) {
                    setTimeout(function(){inst.setVal(inst.getVal()+1, false, false, false, 0.1) }, 0);
                }


            });

            var bg = document.createElement("img");
            bg.setAttribute("src", assetsPath+settings.background);
            $(bg).addClass("mastermind-background");
            element.appendChild(bg);

            element.appendChild(guessButton);

            var bitmap = new createjs.Bitmap(assetsPath+settings.hintbg);
            hintContainer = new createjs.Container();
            hintContainer.x = 10;
            hintContainer.y = moduleHeight - 510;
            hintContainer.addChild(bitmap);

            correctText = new createjs.Text(correctString, "20px 'Share Tech Mono'", "#0bff00");
            correctText.x = 195 - 25;
            correctText.y = 20;
            correctText.lineWidth = 195;
            correctText.lineHeight = 22;
            correctText.textAlign = "right";
            hintContainer.addChild(correctText);
            almostCorrectText = new createjs.Text(almostCorrectString, "20px 'Share Tech Mono'", "#0bff00");
            almostCorrectText.x = 195 - 25;
            almostCorrectText.lineWidth = 195;
            almostCorrectText.lineHeight = 22;
            almostCorrectText.textAlign = "right";
            almostCorrectText.y = 120;
            hintContainer.addChild(almostCorrectText);
            hintText = new createjs.Text("", "20px 'Share Tech Mono'", "#0bff00");
            hintText.x = 18;
            hintText.lineWidth = 195-40;
            hintText.lineHeight = 22;
            hintText.textAlign = "left";
            hintText.y = 195;
            hintContainer.addChild(hintText);
            stage.addChild(hintContainer);

            loadedCallback.call();


        }

        this.updateHint = function(hintstring) {
            correctText.text = correctString;
            almostCorrectText.text = almostCorrectString;
            if(settings.showHints)
                hintText.text = hintstring || "";
            //            TweenLite.from(hintContainer,1,{x:"0", onUpdate:this.stageUpdate});
        }

        function makeHint (colournumber, pattern) {
            if (pattern.indexOf(colournumber) == pattern.lastIndexOf(colournumber)) // only if this colour occurs exactly once in the guess
                return settings.hintmessage.format(settings.options[colournumber]);
            else return null
        }
        
        this.guess = function (inputPattern){
            guesses++;
            if(gameOver)
                return;
            if(settings.maxGuesses!=0)
                guessesRemaining.text="remaining: " + (settings.maxGuesses-guesses).toString();

            if(guesses<=3)
                this.showNextTutorial();

            var response = {};
            var hints = [];
            var hintsounds = [];
            
            response[WRONG_COLOUR_WRONG_LOCATION]=0;
            response[RIGHT_COLOUR_RIGHT_LOCATION]=0;
            response[RIGHT_COLOUR_WRONG_LOCATION]=0;
            //            console.log(actualPattern);

            var actualCounts = {};

            for(var i = 0; i< actualPattern.length; i++) {
                var num = actualPattern[i];
                actualCounts[num] = actualCounts[num] ? actualCounts[num]+1 : 1;
            }

            //            console.log(inputPattern);

            var guessCounts = {};

            for(var i = 0; i< inputPattern.length; i++) {
                var num = inputPattern[i];
                guessCounts[num] = guessCounts[num] ? guessCounts[num]+1 : 1;
            }


            var k = 0;

            for(var i=0; i<inputPattern.length; i++){

                if(actualPattern[i]==inputPattern[i]){
                    if(guessCounts[inputPattern[i]]==1) {
                        hints.push(makeHint(inputPattern[i], inputPattern)); // the Purple is in the right position
                        if(inputPattern.indexOf(inputPattern[i]) == inputPattern.lastIndexOf(inputPattern[i]))
                            hintsounds.push(settings.hint_audios[inputPattern[i]]);
                    }
                    else if(guessCounts[inputPattern[i]]>1 && !settings.allowduplicates) {
                        hints.push( settings.noduplicateshint.format(settings.noun_singular) );
                        hintsounds.push(settings.noduplicateshintaudio);
                    }
                    actualCounts[inputPattern[i]]--;
                    guessCounts[inputPattern[i]]--;
                    response[RIGHT_COLOUR_RIGHT_LOCATION]++;
                    k++;
                }
            }

            for(var i=0; i<settings.optionImages.length; i++){
                while(actualCounts[i] > 0) {
                    actualCounts[i]--;
                    if (guessCounts[i]>0)
                    {
                        guessCounts[i]--;
                        response[RIGHT_COLOUR_WRONG_LOCATION]++;
                        k++;
                    }
                }
            }

            while(k < patternLength){
                response[WRONG_COLOUR_WRONG_LOCATION]++;
                k++;
            }

            //            console.log(guessCounts);



            this.drawResponse(response, hints, hintsounds);
            this.drawGuess(inputPattern);

            this.moveThingsUp();

            if(response[RIGHT_COLOUR_RIGHT_LOCATION]==patternLength){
                this.revealPattern();
                this.win();
                gameOver = true;
            }
            else if(guesses>=settings.maxGuesses && settings.maxGuesses!=0){
                this.revealPattern();
                this.lose();
                gameOver = true;
            }


        }

        this.showNextTutorial = function() {
            $('.mastermind-tutorial-'+(tutorialprogress)).slideUp();
            tutorialprogress++;
            if(settings.tutorialaudios[tutorialprogress]!=null) {
                createjs.Sound.play(assetsPath+settings.tutorialaudios[tutorialprogress]);
            }
            $('.mastermind-tutorial-'+(tutorialprogress)).slideDown();
        }

        this.win = function(){
            this.music.paused=true;
            this.music.stop();
            createjs.Sound.play("mastermind-winSound");
            setTimeout(winCallback, 2000);
        }

        this.lose = function(){
            loseSound = createjs.Sound.play("mastermind-loseSound");

            losepopup = new Dialog("Unlock failed", settings.loseText, settings.loseButton, 'mastermind-loseVO', this.restart.bind(this), "error");
            
//            losepopup.show();
            setTimeout(losepopup, 2000);
        }

        this.revealPattern = function (){
            if(firstSound!=null) {
                firstSound.paused=true;
                firstSound.stop();
            }
            if(secondSound!=null) {
                secondSound.paused=true;
                secondSound.stop()
            }
            //$(guessButton).attr('disabled','disabled');
            for(var i=0; i<patternLength; i++){
                var bitmap = new createjs.Bitmap(assetsPath+settings.optionImages[actualPattern[i]]);
                bitmap.x = (moduleWidth/2) + i*(imageWidth+xMargin*3/2) - (patternLength/2 * (imageWidth+xMargin*3/2)) + xMargin + imageWidth/2;
                bitmap.y = imageHeight/2;
                bitmap.regX = imageWidth/2;
                bitmap.regY = imageHeight/2;
                revealElements.push(bitmap);
                stage.addChild(bitmap);
            }
            //stage.update();
            TweenLite.from(revealElements,2.5,{alpha:0,onUpdate:this.stageUpdate});
            TweenLite.to(revealPanel,1,{y:"-150"});
        }

        this.guessFromSelects = function(){
            createjs.Sound.play("mastermind-guessClick");
            $(guessButton).attr('disabled','disabled');
            var guesses = new Array(patternLength);

            selects.each(function( index ) {
                guesses[index] =parseInt($(this).mobiscroll('getValue')[0]);
            });
            this.guess(guesses);
        }

        this.drawGuess = function(inputPattern){
            for(var i=patternLength-1; i>-1; i--){
                /*var circle = new createjs.Shape();
                circle.graphics.beginFill(options[inputPattern[i]]).drawCircle(0, 0, 25);
                circle.x = 50 * (i+1);
                circle.y = 50*guesses;
                stage.addChild(circle);*/

                var bitmap = new createjs.Bitmap(assetsPath+settings.optionImages[inputPattern[i]]);

                bitmap.x = (moduleWidth/2) + i*(imageWidth+xMargin*3/2) - (patternLength/2 * (imageWidth+xMargin*3/2)) + xMargin;
                bitmap.y = moduleHeight+yMargin*2 - 2*imageHeight;

                stage.addChild(bitmap);
                guessesAndResponses.push(bitmap);
                TweenLite.from(bitmap,0.5,{regY:-imageHeight,onUpdate:this.stageUpdate});
            }

            stage.update();
        }

        this.drawResponse = function (response, hintstrings, hintsounds){

            var bitmap = new createjs.Bitmap(assetsPath+settings.responsebg);
            var responseContainer = new createjs.Container();
            responseContainer.x = moduleWidth-150 - 15;
            responseContainer.y = moduleHeight+yMargin*2 - 2*imageHeight + yMargin/2;
            responseContainer.regY = -30;

            responseContainer.addChild(bitmap);

            var correctCountText = new createjs.Text(response[RIGHT_COLOUR_RIGHT_LOCATION].toString(), "50px popregular", "#0bff00");
            //            correctCountText.regY = correctCountText.getMeasuredHeight()/2;
            correctCountText.y = 0;//bitmap.image.height / 2;
            correctCountText.x = 10;
            responseContainer.addChild(correctCountText);
            var almostCorrectCountText = new createjs.Text(response[RIGHT_COLOUR_WRONG_LOCATION].toString(), "50px popregular", "#0bff00");
            //            almostCorrectCountText.regY = almostCorrectCountText.getMeasuredHeight()/2;
            almostCorrectCountText.y = 0;//bitmap.image.height / 2;
            almostCorrectCountText.x = 80;
            responseContainer.addChild(almostCorrectCountText);


            stage.addChild(responseContainer);
            guessesAndResponses.push(responseContainer);

            var correctNum = response[RIGHT_COLOUR_RIGHT_LOCATION].toString() + " " + (response[RIGHT_COLOUR_RIGHT_LOCATION] == 1 ? settings.noun_singular : settings.noun_plural) + "\n";
            var almostCorrectNum = response[RIGHT_COLOUR_WRONG_LOCATION].toString() + " " + (response[RIGHT_COLOUR_WRONG_LOCATION] == 1 ? settings.noun_singular : settings.noun_plural) + "\n";

            correctString = settings.right_place.format(correctNum);
            almostCorrectString = settings.wrong_place.format(almostCorrectNum);


            if(firstSound!=null) {
                firstSound.paused=true;
                firstSound.stop();
            }
            if(secondSound!=null) {
                secondSound.paused=true;
                secondSound.stop();
            }
            
            var whichHint = Math.floor((Math.random() * hintstrings.length));
            
            if(hintsounds.length>0) {
                if(hintsounds[whichHint]!=null)
                    firstSound = createjs.Sound.play(assetsPath+hintsounds[whichHint]);
            }
            else {
                if(settings.right_place_audios[response[RIGHT_COLOUR_RIGHT_LOCATION]]!=null)
                    firstSound = createjs.Sound.play(assetsPath+settings.right_place_audios[response[RIGHT_COLOUR_RIGHT_LOCATION]]);

                var soundCallback = function () {
                    if(settings.wrong_place_audios[response[RIGHT_COLOUR_WRONG_LOCATION]]!=null)
                        secondSound = createjs.Sound.play(assetsPath+settings.wrong_place_audios[response[RIGHT_COLOUR_WRONG_LOCATION]]);
                };

                if(firstSound!=null && firstSound.playState=="playSucceeded") {
                    firstSound.on("complete", soundCallback);
                }
                else {
                    soundCallback();
                }
            }

            this.updateHint(hintstrings[whichHint]);

            stage.update();
        }

        function newPattern (length) {
            var ret = [];
            var options = [];
            for(var i=0; i<settings.optionImages.length; i++)
                options.push(i);


            if(settings.allowduplicates || length <= options.length) {
                for(var i=0; i<length; i++) {
                    var index = Math.floor(Math.random()*options.length);
                    ret[i] = options[index];
                    if(!settings.allowduplicates)
                        options.splice(index, 1);
                }
            }
            else {
                console.log("error: not enough options to generate pattern of this length");
                settings.allowduplicates = true;
                return false;
                ret = newPattern (length);
            }

            return ret;
        }

        this.moveThingsUp = function (){

            TweenLite.to(guessesAndResponses,0.2,{y:"-="+(imageHeight+yMargin),onUpdate:this.stageUpdate,onComplete:this.enableButton});
//            this.enableButton();
        }

        this.enableButton = function(){
            $(guessButton).removeAttr('disabled');
            for (var i=0; i<guessesAndResponses.length;i++) {
                guessesAndResponses[i].x = Math.round(guessesAndResponses[i].x);
                guessesAndResponses[i].y = Math.round(guessesAndResponses[i].y);
            }

        }

        this.stageUpdate = function(){
            for (var i=0; i<guessesAndResponses.length;i++) {
                if(guessesAndResponses[i].y < (imageHeight+yMargin))
                    guessesAndResponses[i].alpha = 0;
            }
            stage.update();
        }

        this.restart = function(){
            if(loseSound!=null) {
                loseSound.paused=true;
                loseSound.stop();
            }
            losepopup.hide();
            gameOver = false;
            for (var i=0; i<guessesAndResponses.length;i++) {
                stage.removeChild(guessesAndResponses[i]);
            }
            for (var i=0; i<revealElements.length;i++) {
                stage.removeChild(revealElements[i]);
            }
            guessesAndResponses=[];
            revealElements=[];
            //TweenLite.to(grayout,1,{alpha:"0"});
            TweenLite.to(revealPanel,1,{y:"0", onUpdate:this.stageUpdate});
            guesses = 0;
            if(settings.maxGuesses!=0)
                guessesRemaining.text="remaining: " + (settings.maxGuesses-guesses).toString();
            stage.update();
            $(selects).mobiscroll("setVal", ["0"]);
            correctString = "";
            almostCorrectString = "";
            this.updateHint();

            actualPattern = newPattern(patternLength);
            this.enableButton();

            stage.update();
        }

        this.showHelp = function(){
            instructionsCallback.call();
        }

        this.stop = function(){

            TweenMax.killTweensOf(guessesAndResponses);
            TweenMax.killTweensOf(element);
            TweenMax.killTweensOf(revealElements);
            TweenMax.killTweensOf(revealPanel);
            createjs.Ticker.removeAllEventListeners();

        }

        if (!String.prototype.format) {
            String.prototype.format = function() {
                var args = arguments;
                return this.replace(/{(\d+)}/g, function(match, number) {
                    return typeof args[number] != 'undefined'
                        ? args[number]
                    : match
                    ;
                });
            };
        }

        this.init();
    }



    return Module;


});


define('mastermind', ['mastermind/module'], function (main) { return main; });

