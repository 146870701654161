define('hotcold/module',['jquery','text!./template.tpl','underscore','preload','googlemaps','MarkerWithLabel','sound','typewriter','tweenmax'],function($, template, _){

    var Module = function Module(element, config, assetsPath, loadedCallback, loadingProgressCallback, instructionsCallback, winCallback ) {
        assetsPath += assetsPath.length ? '/' : '';

        var settings = {};
        var activeClue = 0;
        var totalClues = config.locations.length;
        var container;
        var addRadiusInMeters;
        var clueStartedTime;
        var expandStartedTime;
        var map;
        var gaugeOuterRangeInMeters;
        var distanceInMeters;
        var pollGPSId;
        var pollCompassInterval;
        var currentPosition;
        var currentHeading;
        var lastAlertTime;
        var lastAlertDistance;
        var isOnMainPage;
        var gpsTimeoutTimeout;
        var hasAccurateGPS;

        this.init = function() {

            settings = config;

            // preloading
            var queue = new createjs.LoadQueue();
            queue.installPlugin(createjs.Sound);

            if ( settings.start.introVO )
                queue.loadFile({id:"hotcold-intro-vo", src: assetsPath + settings.start.introVO});
            if ( settings.end.endingVO )
                queue.loadFile({id:"hotcold-ending-vo", src: assetsPath + settings.end.endingVO});
            if( settings.waypointVO )
                queue.loadFile({id:"hotcold-waypoint-vo", src: assetsPath + settings.waypointVO});

            queue.loadFile({id:"hotcold-alert", src:assetsPath + settings.alertSound});
            queue.loadFile({id:"hotcold-action", src:assetsPath + settings.actionSound});
            queue.loadFile({id:"hotcold-action-complete", src:assetsPath + settings.actionCompleteSound});
            queue.loadFile({id:"hotcold-click", src:assetsPath + settings.buttonSound});
            queue.loadFile({id:"hotcold-reward", src:assetsPath + settings.rewardSound});
            queue.loadFile({id:"hotcold-win", src:assetsPath + settings.winSound});
            queue.loadFile({id:"hotcold-music", src: assetsPath + settings.music});
            queue.loadFile({id:"hotcold-screen-appear", src:assetsPath + settings.screenAppearSound});
            queue.loadFile({id:"hotcold-button-appear", src:assetsPath + settings.buttonAppearSound});

            // insert css and preload images in it
            $('head').append($('<link>').attr({rel: 'stylesheet', href: assetsPath + 'module.css'}));
            $.get(assetsPath+'module.css', function(response){
                var matches = [];
                response.match(/url\([^)]+\)/g).forEach(function(match){
                    match = match.substring(4, match.length-1);
                    if ( !_(matches).contains(match) ) {
                        queue.loadFile(assetsPath + match);
                        matches.push(match);
                    }
                });
            });

            queue.on("complete", loadedCallback);
            if ( loadingProgressCallback ) {
                queue.on("progress", function (e) {
                    loadingProgressCallback.call(null, e.progress)
                });
            }

            queue.load();

            // initialize content
            $(element).addClass('hotcold-screen');

            container = $('<div>').appendTo(element);
            container.addClass("hotcold-container");
            container.html(template);
            container.find('.hotcold-startPage-header').text(settings.start.headerText);
            container.find('.hotcold-startPage-intro').html(settings.start.introText);
            container.find('.hotcold-clue-noun-singular').text(settings.noun.singular);
            container.find('.hotcold-clue-noun-plural').text(settings.noun.plural);
            container.find('.hotcold-clue-total').text(totalClues);
            for ( var i=0; i<totalClues; i++ ) container.find('.hotcold-main-checklist, .hotcold-success-checklist').append('<li>'+(i+1)+'</li>');

            // events

            container.find('.hotcold-startButton').on("click",function(){
                createjs.Sound.play("hotcold-click");
                this.showGPSLoading();
            }.bind(this));

            container.find('.hotcold-action-button').on('mousedown touchstart', function(){
                this.startAction();
            }.bind(this));

            container.find('.hotcold-action-button').on('mouseup touchend', function(){
                this.stopAction();
            }.bind(this));

            container.find('.hotcold-nextButton').on("click",function(){
                createjs.Sound.play("hotcold-click");
                activeClue++;

                if ( activeClue == settings.locations.length ) {
                    this.showEnd();
                    return;
                }

                this.showMain(true);
            }.bind(this));

            container.find('.hotcold-endButton').on("click", _.once(function(){
                createjs.Sound.play("hotcold-click");
                winCallback.call();
            }.bind(this)));

            container.find('.hotcold-hintButton').on("click",function(){
                createjs.Sound.play("hotcold-click");
                this.showParentCheck();
            }.bind(this));

            container.find('.hotcold-parentCancelButton').on("click",function(){
                createjs.Sound.play("hotcold-click");
                this.showMain();
            }.bind(this));

            container.find('.hotcold-parentLoginButton').on("click",function(){
                createjs.Sound.play("hotcold-click");
                this.doParentCheck();
            }.bind(this));

            container.find('.hotcold-parentSkipButton').on("click",function(){
                createjs.Sound.play("hotcold-click");
                this.showAction();
            }.bind(this));

            container.find('.hotcold-parentContinueButton').on("click",function(){
                createjs.Sound.play("hotcold-click");
                this.showMain();
            }.bind(this));

             container.find('.hotcold-refreshGPSButton').on("click",function(){
                createjs.Sound.play("hotcold-click");
                this.showGPSLoading()
            }.bind(this));

            // sizing/scaling behaviour
            function resize(){
                var winWidth = $(element).innerWidth();
                var winHeight = $(element).innerHeight();
                var elemWidth = 576; //lock dimensions at 16:9 portrait at iPad height
                var elemHeight = 1024;
                var scaleX = winWidth / elemWidth;
                var scaleY = winHeight / elemHeight;
                var scale = Math.min(scaleX, scaleY);
                container.css({
                    position: 'absolute',
                    transform: 'scale('+scale+')',
                    transformOrigin: '0 0',
                    left: (winWidth-scale*elemWidth)/2,
                    top: (winHeight-scale*elemHeight)/2,
                    width: elemWidth,
                    height: elemHeight
                });
            }
            resize();
            $(window).resize(resize);

            // Initialize parent map
            var mapOptions = {
                zoom: 20,
                center: new google.maps.LatLng(settings.locations[0].coords.latitude, settings.locations[0].coords.longitude)
            };
            map = new google.maps.Map(container.find('.hotcold-map')[0],mapOptions);
            var pathCoordinates = [];
            for(var i=0; i<settings.locations.length; i++){
                var myLatlng = new google.maps.LatLng(settings.locations[i].coords.latitude,settings.locations[i].coords.longitude);
                var marker = new MarkerWithLabel ({
                    position: myLatlng,
                    map: map,
                    //title:settings.locations[i].name,
                    labelContent: (i+1).toString(),
                    labelAnchor: new google.maps.Point(25, 40),
                    labelClass: "hotcold-label",
                    labelInBackground: false,
                    icon: ''
                });
                pathCoordinates[i] = myLatlng;
            }
            var flightPath = new google.maps.Polyline({
                path: pathCoordinates,
                geodesic: false,
                strokeColor: '#0000FF',
                strokeOpacity: 1.0,
                strokeWeight: 2
            });
            flightPath.setMap(map);
        };

        this.showPage = function(selector){
            var page = container.find(selector);

            if((this.currentPage && this.currentPage[0] === page[0]) || (this.currentPopover && this.currentPopover[0] === page[0]))
                return;
            
            if ( this.currentPopover ) {
                this.currentPopover.hide();
                this.currentPopover = null;
            }

            if ( page.hasClass('hotcold-popover') ) {
                page.fadeIn();
                this.currentPopover = page;
            }
            else {
                if ( this.currentPage ) {
                    this.currentPage.hide();
                }
                page.fadeIn();
                this.currentPage = page;
            }

        }.bind(this);

        this.doParentCheck = function(){
            // Send the data using post
            if ( settings.parentEmail ) {
                $.post( settings.parentAuthUrl, { "email": settings.parentEmail, "password": container.find('.hotcold-parentPassword').val()} )
                    .done(function() {
                        this.showParentPage();
                    }.bind(this))
                    .fail(function() {
                        alert("Incorrect password.");
                    });
            }
            else {
                this.showParentPage();
            }
        }.bind(this);

        this.startAction = function(){
            container.find('.hotcold-action-button').addClass('active');
            var actionLength = 5000;
            this.actionSound = createjs.Sound.play('hotcold-action');
            if ( window.actionTimeout ) {
                clearTimeout(window.actionTimeout);
            }
            window.actionTimeout = setTimeout(function(){
                container.find('.hotcold-action-button').removeClass('active');
                this.showSuccess();
                createjs.Sound.play('hotcold-action-complete');
            }.bind(this), actionLength);
        }.bind(this);

        this.stopAction = function(){
            container.find('.hotcold-action-button').removeClass('active');
            if ( window.actionTimeout ) {
                clearTimeout(window.actionTimeout);
                window.actionTimeout = null;
            }
            if ( this.actionSound ) {
                this.actionSound.paused=true;
                this.actionSound.stop();
                this.actionSound = null;
            }
        }.bind(this);

        this.loadGPS = function(targetAccuracy, startTime) {
            targetAccuracy = targetAccuracy || settings.desiredAccuracy;
            startTime = startTime || Date.now();
            navigator.geolocation.getCurrentPosition(
                function(position){
                    if (hasAccurateGPS) {
                        return; //already done
                    }
                    console.log("accuracy: " + position.coords.accuracy + " target: " + targetAccuracy);
                    if (position.coords.accuracy<=targetAccuracy) {
                        hasAccurateGPS=true;
                    }
                    else {
                        var duration = (Date.now() - startTime) / 1000;
                        var newTargetAccuracy = Math.min(settings.desiredAccuracy + (settings.minimumAccuracy-settings.desiredAccuracy)/settings.secondsToWaitForAccuracy*duration, settings.minimumAccuracy);
                        setTimeout(this.loadGPS.bind(this, newTargetAccuracy, startTime), 100);
                    }
                }.bind(this),
                this.GPSError.bind(this),
                {timeout: settings.secondsToWaitForAccuracy*1000, enableHighAccuracy: true, maximumAge: settings.secondsToWaitForAccuracy*1000}
            );
        };
        
        this.showGPSLoading = function() {
            container.find('.hotcold-gps-loading-waiting').show();
            container.find('.hotcold-gps-loading-failed').hide();
            this.showPage('.hotcold-gpsLoadingPage');

            if(hasAccurateGPS)
                setTimeout(this.showMain.bind(this, true), 100);
            else
                setTimeout(this.showGPSLoading.bind(this), 100);

        };

        this.showMain = function(reset){
            isOnMainPage = true;

            if ( reset ) {
                clueStartedTime = Date.now();
                lastAlertTime = Date.now();
                lastAlertDistance = 0;
                addRadiusInMeters = 0;
                expandStartedTime = 0;
                container.find('.hotcold-meter-step-on').addClass('off').removeClass('on').removeClass('active');
            }

            if ( activeClue > 0 ) {
                gaugeOuterRangeInMeters = this.getDistanceFromLatLonInKm(
                    settings.locations[activeClue].coords.latitude,
                    settings.locations[activeClue].coords.longitude,
                    settings.locations[activeClue - 1].coords.latitude,
                    settings.locations[activeClue - 1].coords.longitude) * 1000 * 1.25;
            }

            // Start GPS polling
            pollGPSId = navigator.geolocation.watchPosition(this.updatePosition.bind(this), this.GPSError.bind(this), {timeout: 60000,enableHighAccuracy: true,maximumAge: 60000});

            // Start compass polling
            if ( navigator.compass ) {
                pollCompassInterval = window.setInterval(this.pollCompass.bind(this), settings.secondsPerCompassUpdate * 1000);
                this.pollCompass();
            }

            container.find('.hotcold-main-checklist li').slice(0, activeClue).addClass('completed');
            container.find('.hotcold-mainPage').find('.hotcold-clue-number').text(activeClue + 1);

            // animations
            TweenMax.fromTo(container.find('.hotcold-mainPage-wrapper'), 1, {scaleX:0}, {scaleX:1, delay:0.5});
            TweenMax.fromTo(container.find('.hotcold-main-header'), 1, {scale:0}, {scale:1, delay:1.3, ease:Back.easeOut});
            TweenMax.fromTo(container.find('.hotcold-compass'), 1, {alpha:0}, {alpha:1, delay:2});
            TweenMax.fromTo(container.find('.hotcold-hintButton'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut, delay:3});
            createjs.Sound.play('hotcold-screen-appear', {delay:500});
            createjs.Sound.play('hotcold-button-appear', {delay:3000});

            this.showPage('.hotcold-mainPage');
        }.bind(this);

        this.showAction = function(){
            isOnMainPage = false;

            this.resetTracking();

            container.find('.hotcold-action-text').html(settings.locations[activeClue].actionText).typewriter();

            // animations
            TweenMax.fromTo(container.find('.hotcold-actionPage-wrapper'), 1, {scaleX:0}, {scaleX:1, delay:0.5});
            TweenMax.fromTo(container.find('.hotcold-action-text'), 1, {scaleY:0}, {scaleY:1, delay:1});
            createjs.Sound.play('hotcold-screen-appear', {delay:500});

            // vo
            if ( settings.waypointVO ) {
                createjs.Sound.play('hotcold-waypoint-vo', {delay:1000});
            }

            this.showPage('.hotcold-actionPage');
        };

        this.showSuccess = function(){
            if ( window.actionTimeout ) {
                clearTimeout(window.actionTimeout);
                window.actionTimeout = null;
            }
                
            createjs.Sound.play("hotcold-reward");
            container.find('.hotcold-success-reward-text').html(settings.locations[activeClue].rewardText).typewriter();
            container.find('.hotcold-success-checklist li').slice(0, activeClue+1).addClass('completed');
            container.find('.hotcold-successPage').find('.hotcold-clue-number').text(activeClue + 1);

            // animations
            TweenMax.fromTo(container.find('.hotcold-successPage-wrapper'), 1, {scaleX:0}, {scaleX:1, delay:0.5});
            TweenMax.fromTo(container.find('.hotcold-success-header'), 1, {scale:0}, {scale:1, delay:1.3, ease:Back.easeOut});
            TweenMax.fromTo(container.find('.hotcold-success-checklist li.completed'), 0.5, {scale:0}, {scale:1, delay:2.2});
            TweenMax.fromTo(container.find('.hotcold-success-checklist li:not(.completed)'), 0.5, {scale:0}, {scale:1, delay:2.7});
            TweenMax.fromTo(container.find('.hotcold-nextButton'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut, delay:4});
            createjs.Sound.play('hotcold-screen-appear', {delay:500});
            createjs.Sound.play('hotcold-button-appear', {delay:4000});

            this.showPage('.hotcold-successPage');
        }.bind(this);

        this.showEnd = function(){
            createjs.Sound.play("hotcold-reward");

            // animations
            TweenMax.fromTo(container.find('.hotcold-endPage-wrapper'), 1, {scaleX:0}, {scaleX:1, delay:0.5});
            TweenMax.fromTo(container.find('.hotcold-endPage-header'), 1, {scale:0}, {scaleX:1, delay:1.3, ease:Back.easeOut});
            var buttonTween = TweenMax.fromTo(container.find('.hotcold-endButton'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut}).pause();
            createjs.Sound.play('hotcold-screen-appear', {delay:500});

            // start typewriter and show button once it's done
            container.find('.hotcold-endPage-ending').html(settings.end.endingText).typewriter(undefined, true, function(){
                buttonTween.resume();
                createjs.Sound.play('hotcold-button-appear');
            });

            // start VO
            createjs.Sound.play('hotcold-win');
            if ( settings.end.endingVO ) {
                createjs.Sound.play('hotcold-ending-vo', {delay: 2000});
            }

            this.music.paused=true;
            this.music.stop();

            this.showPage('.hotcold-endPage');
        }.bind(this);

        this.showHint = function(){
            this.showPage('.hotcold-hintPage');
        }.bind(this);

        this.showParentCheck = function(){
            container.find('.hotcold-parentPassword').val('');
            this.showPage('.hotcold-parentCheck');
        }.bind(this);

        this.showParentPage = function(){
            this.showPage('.hotcold-parentPage');

            google.maps.event.trigger(map, 'resize');
            map.panTo(new google.maps.LatLng(settings.locations[activeClue].coords.latitude, settings.locations[activeClue].coords.longitude));

        }.bind(this);

        /**
         * Start animations, sounds, etc.
         */
        this.start = function() {
            this.showPage('.hotcold-startPage');

            // animations
            TweenMax.fromTo(container.find('.hotcold-startPage-wrapper'), 1, {scaleX:0}, {scaleX:1, delay:0.5});
            TweenMax.fromTo(container.find('.hotcold-startPage-header'), 1, {scale:0}, {scale:1, delay:1.3, ease:Back.easeOut});
            var buttonTween = TweenMax.fromTo(container.find('.hotcold-startButton'), 0.5, {alpha:0, left:-400}, {alpha:1, left:0, ease:Back.easeOut}).pause();
            createjs.Sound.play('hotcold-screen-appear', {delay:500});

            // start typewriter and show button once it's done
            container.find('.hotcold-startPage-intro').typewriter(70, true, function(){
                buttonTween.resume();
                createjs.Sound.play('hotcold-button-appear');
            });


            // start music
            this.music = createjs.Sound.play('hotcold-music', {volume:0.2, loop:-1});

            // start VO
            if ( settings.start.introVO ) {
                createjs.Sound.play('hotcold-intro-vo', {delay: 2000});
            }
            
            this.hasAccurateGPS = false;
            this.loadGPS();
            
        };

        //
        // TRACKING FUNCTIONALITY
        //

        this.resetTracking = function(){
            if ( pollGPSId ) {
                navigator.geolocation.clearWatch(pollGPSId);
                pollGPSId = null;
            }
            if ( pollCompassInterval ) {
                window.clearInterval(pollCompassInterval);
                pollCompassInterval = null;
            }
        };

        this.updateFudging = function(){
            var secondsOnThisClue = ( Date.now() - clueStartedTime ) / 1000;

            if(secondsOnThisClue >= settings.secondsBeforeRadiusExpands && distanceInMeters < settings.distanceBeforeRadiusExpands) {
                if(expandStartedTime < clueStartedTime) {
                    console.log("radius starting expansion at " + distanceInMeters + " meters to target");
                    expandStartedTime = Date.now();
                }
                
                var secondsExpanding = ( Date.now() - expandStartedTime ) / 1000;
                
                var newAddRadius = secondsExpanding * settings.rateOfExpansionInMetersPerSecond;
                addRadiusInMeters = Math.min( distanceInMeters - settings.radiusInMeters , newAddRadius);
            }
            else {
                expandStartedTime = 0; // if you walk away, reset the radius expansion?
            }
        };

        this.GPSError = function(error) {
            console.error(error);
            if(error.code != 3){ // 3 is a timeout error, we don't care about those
                container.find('.hotcold-gps-loading-waiting').hide();
                container.find('.hotcold-gps-loading-failed').show();
                hasAccurateGPS = false;
                this.resetTracking();
                this.showGPSLoading();
                this.loadGPS();
            }
        };

        this.pollCompass = function() {
            navigator.compass.getCurrentHeading(this.updateHeading.bind(this), this.compassError.bind(this));
        };

        this.compassError = function(error) {
            console.log(error);
        };

        this.updatePosition = function(position) {
            this.updateFudging();
            
            if ( currentPosition && currentPosition.timestamp >= position.timestamp ) {
                return;
            }

            // discard updates when distance travelled is less than a factor of accuracy
            if ( currentPosition && this.getDistanceFromLatLonInKm(currentPosition.coords.latitude, currentPosition.coords.longitude, position.coords.latitude, position.coords.longitude)*1000 < position.coords.accuracy ) {
                return;
            }

            currentPosition = position;

            // when starting on the first location, this value will be 0, so set it using the first collected location
            if( !gaugeOuterRangeInMeters ){
                gaugeOuterRangeInMeters = this.getDistanceFromLatLonInKm(
                    position.coords.latitude,
                    position.coords.longitude,
                    settings.locations[0].coords.latitude,
                    settings.locations[0].coords.longitude) * 1000 * 1.25;
            }

            this.updateUI();
        };

        this.updateHeading = function(heading) {
            //use heading.magneticHeading
            currentHeading = heading;
            this.updateUI();
        };

        this.updateUI = function() {
            if ( !currentPosition ) // wait for position data before doing anything
                return;
            if ( !isOnMainPage ) // no UI to update if we're not on the main page
                return;

            distanceInMeters = this.getDistanceFromLatLonInKm(
                currentPosition.coords.latitude,
                currentPosition.coords.longitude,
                settings.locations[activeClue].coords.latitude,
                settings.locations[activeClue].coords.longitude) * 1000;

            var targetBearing = this.getBearing(
                currentPosition.coords.latitude,
                currentPosition.coords.longitude,
                settings.locations[activeClue].coords.latitude,
                settings.locations[activeClue].coords.longitude);

            // percentage to destination, 100 means you're there
            var percentage = Math.max(
                ((gaugeOuterRangeInMeters - distanceInMeters))
                /
                ((gaugeOuterRangeInMeters) - (settings.radiusInMeters + addRadiusInMeters))
                , 0);

            // If gps reading not accurate enough, don't update visuals
            var accuracy = currentPosition.coords.accuracy;
            if(accuracy>distanceInMeters) {
                console.warn("accuracy: " + accuracy + " distance: " + distanceInMeters + " percentage: " + percentage + " radius: " + (settings.radiusInMeters + addRadiusInMeters).toString());
                
                // the two circles overlap
                if((distanceInMeters - (settings.radiusInMeters + addRadiusInMeters) - accuracy) < 0 ) {
                    console.error("accuracy too low! we're in false positive territory");
                    // TODO due to the expanding radius, this will continue to happen unless accuracy improves
                } 
                
                container.find('.hotcold-direction-text').stop().animate({opacity: 0}, 300);
            }
            else {
                console.log("accuracy: " + accuracy + " distance: " + distanceInMeters + " percentage: " + percentage + " radius: " + (settings.radiusInMeters + addRadiusInMeters).toString());
                // only use accuracy radius when it is less than the distance between points
                distanceInMeters -= accuracy;
            }
            
            // the center of our position circle lies within the target circle
            // Jump to success screen?
            if(distanceInMeters <= (settings.radiusInMeters + addRadiusInMeters)){
                this.showAction();
                return;
            }

            // Update distance meter
            var steps = container.find('.hotcold-meter-step-on');
            var currentStep = Math.max(Math.ceil(steps.length * percentage) - 1, 0);
            steps.each(function(i, step){
                step = $(step);
                if ( i < currentStep && !step.hasClass('on') ) {
                    step.addClass('on').removeClass('off').removeClass('active');
                } else if ( i == currentStep && !step.hasClass('active') ) {
                    step.addClass('active').removeClass('on').removeClass('off');
                } else if ( i > currentStep && !step.hasClass('off') ) {
                    step.addClass('off').removeClass('on').removeClass('active');
                }
            });

            // Update hot/cold alerts
            if ( !lastAlertDistance ) {
                lastAlertDistance = distanceInMeters;
            }
            if ( (Date.now() - lastAlertTime) / 1000 > settings.secondsPerAlert ) {
                if ( Math.abs(distanceInMeters - lastAlertDistance) > settings.minimumAlertDistanceInMeters ) {
                    var element = distanceInMeters < lastAlertDistance ? container.find('.hotcold-alert-hot-on') : container.find('.hotcold-alert-cold-on');
                    element.addClass('on');
                    setTimeout(function(){element.removeClass('on')}, 5000);
                    lastAlertDistance = distanceInMeters;

                    createjs.Sound.play('hotcold-alert');
                }
            }

            // Update compass display. Bear in mind we might not ever receive this data.
            if ( currentHeading ) {
                var rotation = (targetBearing - currentHeading.magneticHeading + 360) % 360;
                TweenMax.to(container.find('.hotcold-compass-arrow'), 0.5, {rotation: rotation+'_short'}); //_short autochooses CW or CCW based on whats faster

                var direction = '';
                var coneWidth = 90;
                if ( rotation > coneWidth/2 && rotation < 180 - coneWidth/2 ) {
                    direction = 'Turn Right';
                } else if ( rotation >= 180 - coneWidth/2 && rotation <= 180 + coneWidth/2 ) {
                    direction = 'Turn Around';
                } else if ( rotation > 180 + coneWidth/2 && rotation < 360 - coneWidth/2 ) {
                    direction = 'Turn Left';
                } else {
                    direction = 'Go Forward';
                }

                var directionDisplay = container.find('.hotcold-direction-text');
                if ( directionDisplay.text() != direction ) {
                    container.find('.hotcold-direction-text').stop().animate({opacity: 0}, 300).text(direction).animate({opacity: 1}, 300);
                }
            }

        };


        // MATH AND GEOMETRY

        this.getDistanceFromLatLonInKm = function (lat1, lon1, lat2, lon2) {
          var p = 0.017453292519943295;    // Math.PI / 180
          var c = Math.cos;
          var a = 0.5 - c((lat2 - lat1) * p)/2 + 
                  c(lat1 * p) * c(lat2 * p) * 
                  (1 - c((lon2 - lon1) * p))/2;

          return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
        }

        this.getBearing = function(lat1, lon1, lat2, lon2){
            var y = Math.sin(lon2-lon1) * Math.cos(lat2);
            var x = Math.cos(lat1)*Math.sin(lat2) - Math.sin(lat1)*Math.cos(lat2)*Math.cos(lon2-lon1);
            var brng = Math.atan2(y, x) * (180/Math.PI);
            return (brng +360) % 360;
        };

        this.bearingToCardinal = function(bearing){
            if (bearing >= 22.5 && bearing < 67.5) return "NE";
            else if (bearing >= 67.5 && bearing < 112.5) return "E";
            else if (bearing >= 112.5 && bearing < 157.5) return "SE";
            else if (bearing >= 157.5 && bearing < 202.5) return "S";
            else if (bearing >= 202.5 && bearing < 247.5) return "SW";
            else if (bearing >= 247.5 && bearing < 292.5) return "W";
            else if (bearing >= 292.5 && bearing < 337.5) return "NW";
            else return "N"; // if(bearing < 22.5 || bearing >= 337.5 return "North";
            /*
             if (bearing >= 22.5 && bearing < 67.5) return "North East";
             else if (bearing >= 67.5 && bearing < 112.5) return "East";
             else if (bearing >= 112.5 && bearing < 157.5) return "South East";
             else if (bearing >= 157.5 && bearing < 202.5) return "South";
             else if (bearing >= 202.5 && bearing < 247.5) return "South West";
             else if (bearing >= 247.5 && bearing < 292.5) return "West";
             else if (bearing >= 292.5 && bearing < 337.5) return "North West";
             else return "North"; // if(bearing < 22.5 || bearing >= 337.5 return "North";
             */
        };

        this.init();
    };

    return Module;

});


define('hotcold', ['hotcold/module'], function (main) { return main; });

