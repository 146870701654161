define('framework/ViewController',['require','underscore','jquery','signals','json!config.json'],function(require) {
    var _ = require('underscore');
    var $ = require('jquery');
    var Signal = require('signals').Signal;
    var config = require('json!config.json');

    var ViewController = function(view, postController, moduleController, authController) {

        var _modulePost = null;
        var _moduleInstance = null;
        var _moduleContainer = null;

        var _userHasAttemptedLogin = false;

        /**
         *
         * @param posts Object mapping post IDs to html strings
         */
        this.handleNewPosts = function(posts){
            view.hidePostsLoading();
            view.addPosts(posts);
        }

        this.handleModuleRequest = function(modulePost){
            if ( modulePost.prop ) {
                _modulePost = modulePost;
                moduleController.loadModule(modulePost.prop.player_module, modulePost.prop.player_module_config, this.handleModuleReady.bind(this));
            }
        }

        this.handleModuleReady = function(module){
            _moduleContainer = $('<div>');
            _moduleInstance = moduleController.initModule(_modulePost.prop.player_module, _moduleContainer, this.handleModuleLoaded.bind(this), this.handleModuleLoadingProgress.bind(this), this.handleModuleCompleted.bind(this));
        }

        this.handleModuleLoadingProgress = function(percentage){
            view.setModuleLoadingProgress(percentage);
        }

        this.handleModuleLoaded = function(){
            view.setModuleLoaded(_moduleInstance, _moduleContainer);
        }

        this.handleModuleCompleted = function(){
            view.closeModule();
        }

        this.handleAuthError = function(error){
            if ( _userHasAttemptedLogin )
                view.showLoginError(error);
            else
                view.showLogin();
        }

        this.handleAuth = function(data){
            view.hideLogin(data);
        }

        this.handleLoginSubmit = function(login){
            _userHasAttemptedLogin = true;
            authController.auth(login);
        }

        this.handleLogoutSubmit = function() {
            authController.logout();
            view.showLogin();
        }

        this.handlePostComplete = function(post){
            postController.confirmPost(post);
            view.showPostsLoading();
        }

        this.skipLogin = function(){
            view.skipLogin();
        }

        this.showVersion = function(version){
            if ( view.hasOwnProperty('showVersion') )
                view.showVersion(version);
        }

        this.showAppStore = function(url){
            view.showAppStore(url);
        }



        // INITIALIZE
        view.onModuleRequest.add(this.handleModuleRequest, this);
        view.onLoginSubmit.add(this.handleLoginSubmit, this);
        view.onLogoutSubmit.add(this.handleLogoutSubmit, this);
        view.onPostComplete.add(this.handlePostComplete, this);

        postController.onPostsLoaded.add(this.handleNewPosts, this);

        authController.onAuth.add(this.handleAuth, this);
        authController.onAuthError.add(this.handleAuthError, this);

        // show version
        var data = JSON.parse(localStorage.getItem('manifest'));
        if(data!=null) {
            this.showVersion(data.version);
        }
        else {
            $.getJSON('manifest.json', function(data){
                this.showVersion(data.version);
            }.bind(this));
        }

        // show app store alert
        if ( !config.debug && !window.cordova && (/android/i.test(navigator.userAgent) || /iphone|ipad|ipod/i.test(navigator.userAgent)) ) {
            if ( /iphone|ipad|ipod/i.test(navigator.userAgent) ) {
                this.showAppStore(config.iosStoreURL);
            } else if ( /android/i.test(navigator.userAgent) ) {
                this.showAppStore(config.androidStoreURL);
            }
        }
        
    };

    return ViewController;
});

