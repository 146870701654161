define('dialog',['require','jquery','underscore','typewriter','sound'],function(require){
    var $ = require('jquery');
    var _ = require('underscore');
    require('typewriter');
    require('sound');

    var Dialog = function(heading, body, button, voiceoverId, buttonCallback, containerClass){
        this.voiceoverId = voiceoverId;

        this.elem = $('<div class="module-dialog">');
        this.heading = $('<p class="module-dialog-heading">').appendTo(this.elem);
        this.body = $('<p class="module-dialog-body">').appendTo(this.elem);
        this.button = $('<button class="module-dialog-button">').appendTo(this.elem);

        this.blackout = $('<div class="module-dialog-blackout">').css({
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'black',
            opacity: 0.75
        });

        this.heading.text(heading);
        this.body.html(body);

        if ( button )
            this.button.text(button);
        else
            this.button.hide();

        if ( containerClass )
            this.elem.addClass(containerClass);

        buttonCallback = buttonCallback || this.hide.bind(this);

        this.button.on('click', _.once(function(){
            buttonCallback.call();
        }));

        this.resize = function(){
            var elemWidth = parseInt(this.elem.css('width'));
            var elemHeight = parseInt(this.elem.css('height'));
            var winWidth = $(window).innerWidth();
            var winHeight = $(window).innerHeight();
            var scaleX = winWidth / elemWidth;
            var scaleY = winHeight / elemHeight;
            var scale = Math.min(Math.min(scaleX, scaleY), 1);
            this.elem.css({
                transform: 'scale('+scale+')',
                left: (winWidth-scale*elemWidth)/2,
                top: (winHeight-scale*elemHeight)/2
            });
        }.bind(this);
    };

    Dialog.prototype.show = function(animation){
        animation = animation || 'fade';

        $('body').append(this.blackout);
        $('body').append(this.elem);

        this.blackout.hide().fadeIn();
        this.elem.hide().fadeIn();

        this.heading.typewriter();
        this.body.typewriter();

        this.resize();
        $(window).on('resize', this.resize);

        // Determine the largest font size that can fit all the text into the body -- like a boss!
        var fontSize = parseInt(this.body.css('font-size'));
        var sizerDiv = $('<div>').html(this.body.html()).css({
            visibility: 'hidden',
            fontSize: fontSize+'px'
        });
        this.body.append(sizerDiv);
        while ( sizerDiv.innerHeight() > this.body.innerHeight() ) {
            sizerDiv.css('font-size', fontSize-- + 'px');
        }
        this.body.css('font-size', fontSize+'px');
        sizerDiv.remove();

        createjs.Sound.play('dialog-show');
        if ( this.voiceoverId ) {
            this.voiceoverSound = createjs.Sound.play(this.voiceoverId);
        }
    };

    Dialog.prototype.hide = function(animation){
        animation = animation || 'fade';

        this.blackout.fadeOut(function(){
            this.blackout.remove();
        }.bind(this));

        this.elem.fadeOut(function(){
            this.elem.remove();
        }.bind(this));

        $(window).off('resize', this.resize);

        if ( this.voiceoverSound ) this.voiceoverSound.stop();
        createjs.Sound.play('dialog-hide');
    };

    Dialog.initSounds = function(){
        createjs.Sound.registerSounds([
            {id: 'dialog-show', src: 'show.mp3'},
            {id: 'dialog-hide', src: 'hide.mp3'}
        ], '/audio/dialog/');
    };



    return Dialog;

});

