define('jigsaw/module',['jquery','dialog','easel','tweenmax','preload','underscore','sound'],function($, Dialog){

    var Module = function Module(element, config, assetsPath, loadedCallback, loadingProgressCallback, instructionsCallback, winCallback) {
        assetsPath += assetsPath.length ? '/' : '';

        /**
         * Do all initialization here.
         */

        _.mixin({
            'deepIndex': function (array, item) {
                var result = -1;
                _.some(array, function (value, index) {
                    if (_.isEqual(value, item)) {
                        result = index;
                        return true;
                    }
                });
                return result;
            }
        });

        var settings = {};
        var canvas;
        var stage;
        var won = false;
        var pieces = [];
        var occupiedTargets = [];
        var tutorialprogress = 0;


        this.init = function() {

            settings = config;

            canvas = document.createElement("canvas");
            canvas.width = 768;
            canvas.height = 1024;

            element.appendChild(canvas);

            stage = new createjs.Stage(canvas);

            var queue = this.loadQueue = new createjs.LoadQueue(true);
            queue.installPlugin(createjs.Sound);

            for(var i=0;i<settings.pieces.length;i++){
                queue.loadFile(assetsPath+settings.pieces[i].image);
            }
            for(var key in settings.background){
                queue.loadFile({src:assetsPath+settings.background[key], id:key});
            }
            for(var i=0; i<settings.tutorialMessages.length; i++){
                if ( settings.tutorialMessages[i].sound ) {
                    queue.loadFile({src:assetsPath+settings.tutorialMessages[i].sound});
                }
            }

            queue.loadFile({src:assetsPath+settings.mouseUpSound, id:"mouseUp"});
            queue.loadFile({src:assetsPath+settings.mouseDownSound, id:"mouseDown"});
            queue.loadFile({src:assetsPath+settings.lockSound, id:"lockSound"});
            queue.loadFile({src:assetsPath+settings.winSound, id:"winSound"});
            queue.loadFile({src:assetsPath+settings.music, id:"music"});

            queue.on("complete", this.imagesLoaded, this);
            if ( loadingProgressCallback ) {
                queue.on("progress", function(e){ loadingProgressCallback.call(null, e.progress)});
            }

            for(var i=0; i<settings.tutorialMessages.length; i++){
                var tutorialPopup = $('<div>');
                tutorialPopup.addClass("jigsaw-tutorial jigsaw-tutorial-"+i);
                var text = $('<p>');
                text.html(settings.tutorialMessages[i].text);
                tutorialPopup.append(text);
                tutorialPopup.data('sound', assetsPath + settings.tutorialMessages[i].sound);
                element.appendChild(tutorialPopup.get(0));
            }

            // insert css
            $('head').append($('<link>').attr({rel: 'stylesheet', href: assetsPath + 'module.css'}));

        };

        this.imagesLoaded = function(){

            //set up background
            //once this is an inheritance-based module, move this to a subclass and override the function
            var skybottom = new createjs.Bitmap(assetsPath+settings.background.skybottom);
            stage.addChild(skybottom);

            var skytop1 = this.skytop1 = new createjs.Bitmap(assetsPath+settings.background.skytop);
            skytop1.y = 10;
            stage.addChild(skytop1);

            var skytop2 = this.skytop2 = new createjs.Bitmap(assetsPath+settings.background.skytop);
            skytop2.y = 350;
            stage.addChild(skytop2);

            var cloud1 = this.cloud1 = new createjs.Bitmap(assetsPath+settings.background.cloud1);
            cloud1.y = 10;
            stage.addChild(cloud1);

            var cloud2 = this.cloud2 = new createjs.Bitmap(assetsPath+settings.background.cloud2);
            cloud2.y = 250;
            stage.addChild(cloud2);

            var cloud3 = this.cloud3 = new createjs.Bitmap(assetsPath+settings.background.cloud3);
            cloud3.y = 500;
            stage.addChild(cloud3);

            var tree1 = this.tree1 = new createjs.Bitmap(assetsPath+settings.background.tree1);
            tree1.regY = 307;
            tree1.x = -40;
            tree1.y = 287;
            stage.addChild(tree1);

            var tree2 = this.tree2 = new createjs.Bitmap(assetsPath+settings.background.tree2);
            tree2.regX = 340;
            tree2.regY = 298;
            tree2.x = canvas.width + 30;
            tree2.y = 258;
            stage.addChild(tree2);

            var tombstone = new createjs.Bitmap(assetsPath+settings.background.tombstone);
            stage.addChild(tombstone);

            for(var i=0;i<settings.pieces.length;i++){
                pieces[i] = new createjs.Bitmap(this.loadQueue.getResult(assetsPath+settings.pieces[i].image));
                pieces[i].cache(-20, -20, pieces[i].getBounds().width + 40, pieces[i].getBounds().height + 40);

                if(settings.useRotation) {
                    pieces[i].rotation = Math.random() * 360;
                }

                stage.addChild(pieces[i]);
                //pieces[i].scaleX = pieces[i].scaleY = 1.25;

                pieces[i].targets = settings.pieces[i].targets;
                if(settings.pieces[i].length==1)
                    pieces[i].target = pieces[i].targets[0];
                pieces[i].movable = true;
                pieces[i].rotatable = settings.useRotation;
                pieces[i].rotating=false;


                pieces[i].on("mousedown", function(evt) {
                    this.parent.addChild(this);

                    this.offset = {
                        x:this.x-evt.stageX,
                        y:this.y-evt.stageY
                    };
                    if(this.rotatable | this.movable){
                        this.shadow = new createjs.Shadow("rgba(0,0,0,0.8)", 5, 5, 10);
                        this.updateCache();
                        createjs.Sound.play("mouseDown");
                    }
                    if(this.rotatable)
                        this.rotating=true;

                    if(this.movable){
                        occupiedTargets.splice(_.deepIndex(occupiedTargets,{x: this.x, y: this.y}),1);
                    }
                });

                pieces[i].on("pressup", function(evt) {
                    this.shadow = null;
                    this.updateCache();
                    this.rotating=false;

                    occupiedTargets.push({x:this.x,y:this.y});

                    if(settings.useRotation && this.rotatable){
                        this.rotation = (Math.round(this.rotation / settings.rotationSnap)) * settings.rotationSnap;
                    }

                    if(!this.movable && ((this.rotation % 360) == this.target.rotation)){
                        this.rotatable=false;
                    }

                    if(!this.movable && !this.rotatable) {
                        this.filters = [
                            new createjs.ColorFilter(1, 1, 1, 1, 64, 64, 64, 0)
                        ]
                        this.updateCache();
                    }

                    if(this.movable | this.rotatable){
                        createjs.Sound.play("mouseUp");
                    }

                });

                // the pressmove event is dispatched when the mouse moves after a mousedown on the target until the mouse is released.
                pieces[i].on("pressmove", function(evt) {

                    this.rotating=false;

                    if(this.movable){

                        var mouseX = evt.stageX+ this.offset.x;
                        var minX = this.image.width/2 + settings.margins.left;
                        var maxX = canvas.width - settings.margins.right - this.image.width/2;

                        var mouseY = evt.stageY+ this.offset.y;
                        var minY = this.image.height/2 + settings.margins.top;
                        var maxY = canvas.height - settings.margins.bottom - this.image.height/2;

                        this.x = Math.max(minX, Math.min(mouseX, maxX));
                        this.y = Math.max(minY, Math.min(mouseY, maxY));

                        for(var i=0; i<this.targets.length; i++){
                            if(
                                ( Math.abs (this.x - this.targets[i].x) < settings.tolerance )
                                && ( Math.abs (this.y - this.targets[i].y) < settings.tolerance )
                                && ( _.deepIndex ( occupiedTargets, {x:this.targets[i].x,y:this.targets[i].y}) == -1)
                            )
                            {

                                this.x = this.targets[i].x;
                                this.y = this.targets[i].y;
                                this.movable=false;
                                this.target = this.targets[i];
                                createjs.Sound.play("lockSound");

                                occupiedTargets.push({x:this.targets[i].x,y:this.targets[i].y});
                                
                                
                                
                                
                                for(var j=0; j<pieces.length; j++){
                                    if(
                                        ( Math.abs (this.x - pieces[j].x) < settings.tolerance )
                                        && ( Math.abs (this.y - pieces[j].y) < settings.tolerance )
                                        && pieces[j].movable
                                    ) {
                                        // nudge other peices out of the way
                                        
                                        var minX = pieces[j].image.width/2 + settings.margins.left;
                                        var maxX = canvas.width - settings.margins.right - pieces[j].image.width/2;

                                        var minY = pieces[j].image.height/2 + settings.margins.top;
                                        var maxY = canvas.height - settings.margins.bottom - pieces[j].image.height/2;
                                        
                                        var nudgeX = 0; //pieces[j].image.width;
                                        var nudgeY = -pieces[j].image.height/2;
                                        
                                        pieces[j].x = Math.max(minX, Math.min(pieces[j].x+nudgeX, maxX));
                                        pieces[j].y = Math.max(minY, Math.min(pieces[j].y+nudgeY, maxY));
                                        
                                    }
                                }
                                
                                
                                
                                
                                
                                break;
                            }

                        }

                        if (settings.useSlots) {
                            for(var j = 0; j < settings.slots.length; j++){
                                if(
                                    ( Math.abs (this.x - settings.slots[j].x) < settings.tolerance )
                                    && ( Math.abs (this.y - settings.slots[j].y) < settings.tolerance )
                                    && ( _.deepIndex ( occupiedTargets, {x:settings.slots[j].x,y:settings.slots[j].y}) == -1)
                                )
                                {
                                    this.x = settings.slots[j].x;
                                    this.y = settings.slots[j].y;
                                }
                            }
                        }

                    }

                });

            }

            var shuffledPieces = _.shuffle(pieces);
            for(var i=0;i<settings.pieces.length;i++)  {
                if(settings.startInSlots){
                    var randSlot = (i + (settings.slots.length - settings.pieces.length));//Math.floor(Math.random()*settings.slots.length);
                    shuffledPieces[i].x = settings.slots[randSlot].x;
                    shuffledPieces[i].y = settings.slots[randSlot].y;
                    occupiedTargets.push({x:settings.slots[randSlot].x,y:settings.slots[randSlot].y});
                }
                else{

                    var randomX = Math.random();// * canvas.width;
                    var minX = pieces[i].image.width/2 + settings.margins.left;
                    var maxX = canvas.width - settings.margins.right - pieces[i].image.width/2;

                    var randomY = Math.random();// * canvas.height;
                    var minY = pieces[i].image.height/2 + settings.margins.top;
                    var maxY = canvas.height - settings.margins.bottom - pieces[i].image.height/2;

                    shuffledPieces[i].x = randomX*(maxX-minX) + minX;
                    shuffledPieces[i].y = randomY*(maxY-minY) + minY;
                }

            }
            //stage.update();
            createjs.Ticker.addEventListener("tick", this.tick.bind(this));

            createjs.Touch.enable(stage);
            createjs.Ticker.setFPS(60);

            loadedCallback.call();
        };

        /**
         * Start animations, sounds, etc.
         */
        this.start = function() {
            stage.update();

            tutorialprogress = -1;
            this.advanceTutorial();

            this.music = createjs.Sound.play('music', {loop: -1, volume:0.5});

            // move background
            //once this is an inheritance-based module, move this to a subclass and override the function
            TweenMax.to(this.tree1, 5, {rotation:5, repeat:-1, yoyo:true, ease:Cubic.easeInOut});
            TweenMax.to(this.tree2, 6, {rotation:-5, repeat:-1, yoyo:true, ease:Cubic.easeInOut});
            TweenMax.fromTo(this.cloud1, 10, {x:-350}, {x:canvas.width, repeat:-1, ease:Linear.easeInOut});
            TweenMax.fromTo(this.cloud2, 14, {x:canvas.width}, {x:-350, repeat:-1, ease:Linear.easeInOut});
            TweenMax.fromTo(this.cloud3, 18, {x:-350}, {x:canvas.width, repeat:-1, ease:Linear.easeInOut});
            TweenMax.to(this.skytop1, 12, {y:'+=100', repeat:-1, yoyo:true, ease:Cubic.easeInOut});
            TweenMax.to(this.skytop1, 6, {alpha:0.5, repeat:-1, yoyo:true, ease:Cubic.easeInOut});
            TweenMax.to(this.skytop2, 12, {y:'+=100', repeat:-1, yoyo:true, ease:Cubic.easeInOut});
            TweenMax.to(this.skytop2, 6, {alpha:0.5, repeat:-1, yoyo:true, ease:Cubic.easeInOut});
        };

        this.tick = function(event) {

            stage.update(event);
            var correct = 0;

            for(var i=0; i<pieces.length; i++){
                if(!pieces[i].movable && !pieces[i].rotatable){
                    correct++;
                }
                if(pieces[i].rotating){
                    pieces[i].rotation+= settings.rotationSpeed;
                }
            }
            if(correct==pieces.length && !won)
            {
                won=true;
                setTimeout(this.win.bind(this), 2000);
                createjs.Sound.play("winSound");
            }

        };

        this.win = function(){
            this.music.paused=true;
            this.music.stop();
            winCallback();
        };

        this.advanceTutorial = function(){
            $('.jigsaw-tutorial-'+(tutorialprogress)).fadeOut(1000);
            tutorialprogress++;
            $('.jigsaw-tutorial-'+(tutorialprogress)).delay(1000).fadeIn(1000);
            createjs.Sound.play($('.jigsaw-tutorial-'+(tutorialprogress)).data('sound'), {delay:1500});
            setTimeout(this.advanceTutorial.bind(this), 15000);
        };


        this.init();

    };

    return Module;

});


define('jigsaw', ['jigsaw/module'], function (main) { return main; });

