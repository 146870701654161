define('framework/main',['require','underscore','underscoreString','jquery','./ModuleController','./PostController','./ViewController','./AuthController','./Debug','json!config.json'],function(require) {
    var _ = require('underscore');
    var _s = require('underscoreString');
    var $ = require('jquery');
    var ModuleController = require('./ModuleController');
    var PostController = require('./PostController');
    var ViewController = require('./ViewController');
    var AuthController = require('./AuthController');
    var Debug = require('./Debug');
    var config = require('json!config.json');

    var api = new (function(){

        this.start = function(view) {

            var postController = new PostController();
            var moduleController = new ModuleController();
            var authController = new AuthController();
            var viewController = new ViewController(view, postController, moduleController, authController);

            $.ajaxSetup({
                beforeSend: function(jqXHR, settings) {
                    jqXHR.setRequestHeader('X-Authorization', _s.sprintf('SIMPLE-SITE site="%s",login="%s"', config.site, authController.login));
                }
            });

            // load posts on login
            authController.onAuth.add(postController.refresh, postController);

            // allow activities to initialize as soon as posts are loaded
            postController.onPostsLoaded.add(moduleController.handleNewPosts, moduleController);

            if ( !view.mainModuleName ) {
                // no module needed for the main view? get going
                loadStuff();
            }
            else {
                // need a module for the main view? load that up, then get going
                moduleController.loadModule(view.mainModuleName, null, function(){
                    var moduleInstance = moduleController.initModule(view.mainModuleName, view.mainModuleContainer, function(){
                        view.setMainModuleLoaded(moduleInstance);
                        loadStuff();
                    }.bind(this), view.setMainModuleLoadingProgress);
                }.bind(this));
            }

            function loadStuff() {
                var re = /module=([a-zA-Z0-9-_]+)&?/g;

                var _params = re.exec(window.location.search);

                // handle previews
                if ( false ) {
                    // TODO: the appified kids site needs a way to pull preview posts from the server -- until then, a preview with adventure-specific data cannot be shown
                    // the preview of an actual post is generated on the server with adventure-specific data and embedded into a global
                    //viewController.skipLogin();
                    //postController.loadPreviewPost(globals.previewPost);
                }
                else if ( _params != null && _params[1] != "") {

                    // the preview of a static module is assembled on-the-fly with no adventure-specific data
                    var previewPost = {
                        prop: {
                            player_module: _params[1],
                            player_module_config: {}
                        },
                        html: '<p>Hello!</p><p>This is a preview. Click the Start button to launch the module!</p>',
                        button: 'Launch'
                    };
                    viewController.skipLogin();
                    postController.loadPreviewPost(previewPost);
                }
                else {
                    // standard behaviour...

                    authController.auth();
                }
            }
        };

        if ( config.debug ) {
            var debug = new Debug();
            debug.attach();
        }

    })();

    return api;
});



define('framework', ['framework/main'], function (main) { return main; });

