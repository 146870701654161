define('point-and-click/js/Interactable',[], function() {

    function Interactable(params, PCGame) {

        var isOn = params.startenabled || false;
        var target = params.target;
        var oneShot = params.oneshot || false;
        var isLatch = params.latch || false;
        var isLoop = params.loop || false;
        this.name = params.name;
        var messages = params.messages || {};
        var states = [];
        var loopPoints = [];
        var desiredState; // the assumption is that there are two states, and the first one is Off
        var currentState;
        var offState;

        var requiredcursor;
        var requireditem;
        var newcursor;
        var collectable;

        var buttontarget = params.buttontarget;
        var toggletarget = params.toggletarget;

        this.init = function (params) {

            if ( params.overridehitarea != null ) {
                if ( params.overridehitarea == true )
                    if ( target.shape != null ) {
                        target.hitArea = target.shape;
                        target.shape.visible = false;
                    }
            }

            if ( params.collectable )
                collectable = PCGame.collectables[params.collectable];
            else if ( params.newcursor )
                newcursor = params.newcursor;

            if ( params.requireditem ) {
                requireditem = PCGame.collectables[params.requireditem];
                requiredcursor = requireditem.name;
            }
            else if ( params.requiredcursor )
                requiredcursor = params.requiredcursor;

            target.interactable = this;

            target.mouseEnabled = params.mouseEnabled!=null ? params.mouseEnabled : true;
            target.addEventListener("click", this.handleClick);

            states = $.map(target.labels, function(n){return n.label});

            var duration = target.timeline.duration;

            loopPoints = $.map(target.labels, function(n){return (n.position-1 + duration) % duration});
            loopPoints.push(loopPoints.shift());
            desiredState = params.desiredstate != null ? params.desiredstate : states.length-1;
            currentState = isOn ? desiredState : 0;
            target.addEventListener("tick", handleTick.bind(this));
            target.loop = false;
            if(params.fps!=null)
                target.framerate = params.fps;

            offState = states.length == 2 ? 1 - desiredState : 0;

            if(isLoop)
                playAnimation(currentState);
            else
                target.gotoAndStop(loopPoints[offState]);

        }

        function handleTick(event) {
            var currentFrame = event.currentTarget.currentFrame;
            var loopPoint = loopPoints[currentState];
            if(currentFrame == loopPoint)
            {
                repeat.bind(this)();
            }
        }

        this.handleClick = function handleClick(event) {
            //            console.log(name + " clicked with " + PCGame.activeCursor);
            if ( !(isLatch && isOn) ) {
                if ( requiredcursor != null ) {
                    if ( requiredcursor != PCGame.activeCursor ) {
                        // console.log("you don't have the " + requiredcursor + " yet");
                        if ( PCGame.activeCursor != null )
                            playError.bind(this)("wrongcursor");
                        else if(!isOn)
                            playError.bind(this)("nocursor"); // or noitem
                        return;
                    }
                    else {
                        if ( requireditem != null ) {
                            PCGame.Consume(requireditem);
                        }
                        event.isTouch = false; // HACK to only show certain clicks on mobile
                        PCGame.CursorClick(event);
                    }
                }

                if ( states.length > 2 )
                    this.increment();
                else
                    this.toggle();

                if ( newcursor != null )
                    PCGame.SetCursor(newcursor);
                else if ( requireditem != null )
                    PCGame.ClearCursor();

                if ( buttontarget != null )
                {
                    for(var i in buttontarget) {
                        buttontarget[i].interactable.handleClick(event);
                    }
                }

                if ( toggletarget != null )
                {
                    for(var i in toggletarget)
                        toggletarget[i].interactable.set(isOn); // if the target has >2 states, sending set(true) will increment it
                    //TODO: stop using Interactable.set
                }
            }
            else {
                if ( requiredcursor != null ) {
                    if ( requiredcursor != PCGame.activeCursor ) {
                        // already unlocked, but wrong cursor     
                        return;
                    }
                }

                //                console.log(name + " is already unlocked");
                playError.bind(this)("alreadyunlocked");
            }

            this.dispatchEvent('click');

        }.bind(this);

        this.lock = function(){
            //TODO: something better here
            target.mouseEnabled = false;
            desiredState = currentState;
            isLatch = true;
            isOn = true;
            //            playAnimation(currentState)
        }

        function repeat() { // called at the end of each loop
            if ( isOn ) {
                if ( !oneShot ) {
                    if ( !isLoop ) {
                        this.dispatchEvent("complete");
                        stop();
                    }
                    else {
                        playOn();
                    }
                }
                else {
                    this.toggle();
                }
            }
            else {
                if ( !isLoop ) {
                    stop();
                }
                else {
                    playAnimation(currentState); // loop the off animation
                }
            }

        }

        this.increment = function increment() {
            var newState = currentState + 1;
            newState = (newState % states.length);

            this.setState(newState);
        }

        this.toggle = function toggle() {

            var newOn;

            if ( !isLatch && !collectable )
                newOn = !isOn;
            else if ( collectable != null ) {
                newOn = !isOn;
                if(!PCGame.Collect(collectable))
                    return;
            }
            else if ( isLatch )
                newOn = true;

            this.set(newOn);

        }.bind(this);

        this.getState = function getState() {
            return states[currentState];
        }

        this.setState = function setState(newstate) {
            currentState = newstate;
            playAnimation(currentState);
            isOn = currentState == desiredState;
            if ( isOn )
                showLocalizedMessage.bind(this)("activate");
            this.dispatchEvent("change");
        }

        this.setStateByName = function setStateByName (statename) {
            if(states.indexOf(statename)>-1) {
                var newState = states.indexOf(statename);
                this.setState(newState);
            }
            else
                console.error("no state named " + statename);
        }

        this.set = function set(newOn) {
            var wasOn = isOn;
            if ( !((wasOn && newOn) && isLatch) && !(!newOn && isLatch) ) {
                if ( states.length == 2 ) {
                    isOn = newOn; //(collectable != null && collectable.collected) ? false :
                    if ( isOn && !wasOn )
                        showLocalizedMessage.bind(this)("activate");
                    isOn ? playOn() : playOff();
                    if ( newOn )
                        currentState = desiredState;
                    else
                        currentState = 0;
                    this.dispatchEvent("change");
                }
                else if ( newOn )
                    this.increment();
            }
            else {
                //                console.log (name + " is already unlocked");
            }
        }

        this.getTarget = function() {
            return target;
        }

        function playAnimation(state) {
            target.gotoAndPlay(states[state]);
        }

        function playOn() {
            target.gotoAndPlay(states[desiredState]);
        }

        function playOff() {
            target.gotoAndPlay(states[offState]);
        }

        function showLocalizedMessage(messagename) {
            if ( messagename != null )
            {
                this.dispatchEvent(messagename);
                PCGame.showLocalizedMessage( messages[messagename] || messagename );
            }
        }

        function playError(messagename) {
            showLocalizedMessage.bind(this)(messagename);
            // TODO play an animation
            // target.gotoAndPlay(animations[messagename]);
        }

        function stop() {
            target.stop();
            //            target.cache(target.nominalBounds.x, target.nominalBounds.y, target.nominalBounds.width, target.nominalBounds.height)
        }

        this.init(params);
    }

    createjs.EventDispatcher.initialize(Interactable.prototype);

    return Interactable;
});

